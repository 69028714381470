import Image from 'next/image'
import React from 'react'

interface DualIconContainerProps {
  strikeIcon?: string
  riskyIcon?: string
}

const DualIconContainer: React.FC<DualIconContainerProps> = ({ strikeIcon, riskyIcon }) => (
  <>
    <div className="relative wrapper">
      <span className="absolute top-0 left-0 icon-wrapper z-10 flex items-start justify-start">
        <Image src={riskyIcon ? riskyIcon : '/missing-icon.svg'} height={16} width={16} layout="fixed" />
      </span>
      <span className="absolute bottom-0 right-0 icon-wrapper z-0 flex items-start justify-start">
        <Image src={strikeIcon ? strikeIcon : '/missing-icon.svg'} height={16} width={16} layout="fixed" />
      </span>
    </div>
    <style jsx>
      {`
        .wrapper {
          width: 30px;
          height: 20px;
        }
        .icon-wrapper {
          height: 16px;
          width: 16px;
        }
      `}
    </style>
  </>
)

export default DualIconContainer
