// TODO: Clean this shit up

export enum SupportedTestnetChainId {
  // ROPSTEN = 3,
  RINKEBY = 4,
  // GOERLI = 5,
  // KOVAN = 42,

  // ARBITRUM_RINKEBY = 421611,
  // OPTIMISTIC_KOVAN = 69,
}

export enum SupportedProductionChainId {
  MAINNET = 1,
  // ARBITRUM_ONE = 42161,
  // OPTIMISM = 10,
}

export type SupportedChainId = SupportedTestnetChainId | SupportedProductionChainId
export const L1_CHAIN_IDS = [
  SupportedProductionChainId.MAINNET,
  // SupportedTestnetChainId.ROPSTEN,
  // SupportedTestnetChainId.RINKEBY,
  // SupportedTestnetChainId.GOERLI,
  // SupportedTestnetChainId.KOVAN,
] as const
export type chainInformation = {
  name: string
  isTestnet: boolean
  chainId: SupportedChainId
}

export const ALL_SUPPORTED_PRODUCTION_CHAINS: chainInformation[] = [
  {
    name: 'Ethereum',
    isTestnet: false,
    chainId: SupportedProductionChainId.MAINNET,
  },
]

export const ALL_SUPPORTED_TESTNET_CHAINS: chainInformation[] = [
  {
    name: 'Rinkeby',
    isTestnet: true,
    chainId: SupportedTestnetChainId.RINKEBY,
  },
]
export const ALL_SUPPORTED_CHAIN_IDS = ALL_SUPPORTED_PRODUCTION_CHAINS.concat(ALL_SUPPORTED_TESTNET_CHAINS).map(
  (chain) => chain.chainId
)
