import { useMemo } from 'react'
import useSWR, { responseInterface } from 'swr'

import { Provider } from '@ethersproject/abstract-provider'
import { Signer } from '@ethersproject/abstract-signer'
import { EthersRmm } from '@primitivefi/rmm-ethers'

import { useActiveWeb3React } from './web3'

function getEthersRmm(signerOrProvider: Signer | Provider): () => Promise<EthersRmm> {
  return async () => await EthersRmm.connect(signerOrProvider)
}

/**
 * Connects to EthersRmm deployment from the connected provider or signer.
 */
export function useRmmProtocol(suspense = false): responseInterface<EthersRmm, any> {
  const { library: signerOrProvider, chainId } = useActiveWeb3React()
  const shouldFetch = !!signerOrProvider && typeof chainId !== 'undefined'
  const result = useSWR(
    shouldFetch ? [signerOrProvider, 'ethers-rmm', chainId] : null,
    getEthersRmm(signerOrProvider?.getSigner() as any),
    {
      dedupingInterval: 60 * 1000,
      refreshInterval: 60 * 1000,
      suspense,
    }
  )

  return result
}

export function useRmmProtocolAddresses(): Record<string, string> | undefined {
  const { data: rmm } = useRmmProtocol()

  return useMemo(() => {
    return rmm?.connection?.addresses
  }, [rmm?.connection?.addresses])
}
