import { createAction } from '@reduxjs/toolkit'
import { Token, TokenList } from './reducer'
import { Notification } from './reducer'

export const updateOnboarding = createAction<string>('user/updateOnBoarding')
export const setAcceptedTOS = createAction<boolean>('user/setAcceptedTOS')
export const updateStrikeToken = createAction<Token>('user/updateStrikeToken')
export const updateRiskyTokenLists = createAction<{
  [listName: string]: TokenList
}>('user/updateRiskyTokenLists')
export const updateSlippage = createAction<string>('user/updateSlippage')
export const clearNotifications = createAction('user/clearNotifications')
export const addNotification = createAction<Notification>('user/addNotification')
export const selectPreferredChain = createAction<number>('user/selectPreferredChain')
export const setShowBalances = createAction<boolean>('user/setShowBalances')
export const setCanPermit = createAction<boolean>('user/setCanPermit')
export const setInfiniteRiskyApproval = createAction<boolean>('user/setInfiniteRiskyApproval')
export const setLastUpdatedChainLocation = createAction<string>('user/setLastUpdatedChainLocation')
