import { useMemo, useRef, useEffect } from 'react'
import useSWR, { responseInterface, SWRResponse } from 'swr'
import { Block, Web3Provider } from '@ethersproject/providers'
import { useActiveWeb3React } from '@/hooks/web3'
import { BigNumberish } from '@ethersproject/bignumber'
import { parseBytes32String } from '@ethersproject/strings'
import { Contract } from '@ethersproject/contracts'
import { Wei, parseWei, toBN } from 'web3-units'
import { Token, WETH9 } from '@uniswap/sdk-core'

import EIP_2612 from '@/constants/abis/eip_2612.json'
import ERC20_ABI from '@/constants/abis/erc20.json'
import { ERC20_BYTES32 } from '@/constants/index'
import type { ContractInterface } from '@ethersproject/contracts'
import { ENS_REGISTRAR_ADDRESSES } from '@/constants/addresses'
import ENS_ABI from '@/constants/abis/ens-registrar.json'
import ENS_PUBLIC_RESOLVER_ABI from '@/constants/abis/ens-public-resolver.json'

import { getContract } from '@/utils/index'
import { BigNumber } from 'ethers'
import { weiToWei } from '@primitivefi/rmm-sdk'
import { useRmmProtocol } from './useRmmProtocol'
import { isWETH } from '@/utils/isWETH'

export enum DataType {
  BlockNumber,
  ETHBalance,
  TokenBalance,
  TokenAllowance,
  Reserves,
  Token,
  RemoteTokens,
  Option,
  Block,
}

function getBlockNumber(library: Web3Provider): () => Promise<number> {
  return async (): Promise<number> => {
    return library.getBlockNumber()
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useBlockNumber(): responseInterface<number, any> {
  const { library } = useActiveWeb3React()
  const shouldFetch = !!library
  return useSWR(shouldFetch ? [DataType.BlockNumber] : null, getBlockNumber(library as Web3Provider), {
    refreshInterval: 10 * 500,
  })
}

function getBlock(library: Web3Provider, blockNumber: number): () => Promise<Block> {
  return async (): Promise<Block> => {
    return library.getBlock(blockNumber)
  }
}

export const useBlock = (): SWRResponse<Block, any> => {
  const { library } = useActiveWeb3React()
  const shouldFetch = !!library
  const { data } = useBlockNumber()
  return useSWR(shouldFetch ? [data] : null, getBlock(library as Web3Provider, data ? data : 0), {
    refreshInterval: 10 * 500,
  })
}

// returns null on errors
export function useContract<T extends Contract = Contract>(
  addressOrAddressMap: string | { [chainId: number]: string } | undefined,
  ABI: ContractInterface,
  withSignerIfPossible = true
): T | null {
  const { library, account, chainId } = useActiveWeb3React()

  return useMemo(() => {
    if (!addressOrAddressMap || !ABI || !library || !chainId) return null
    let address: string | undefined
    if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap
    else address = addressOrAddressMap[chainId]
    if (!address) return null
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [addressOrAddressMap, ABI, library, chainId, withSignerIfPossible, account]) as T
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean) {
  return useContract<any>(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useTotalSupply(token?: Token): Wei | undefined {
  const contract = useTokenContract(token?.isToken ? token.address : undefined, false)

  const totalSupply: BigNumberish = useContract(contract, 'totalSupply')?.result?.[0]

  return token?.isToken && totalSupply ? parseWei(totalSupply) : undefined
}

function getTokenBalance(contract: Contract, token: Token): (address: string) => Promise<Wei> {
  return async (address: string): Promise<Wei> =>
    contract.balanceOf(address).then((balance: { toString: () => string }) => weiToWei(balance.toString(), token.decimals))
}

function getETHBalance(signer): () => Promise<Wei> {
  return async (): Promise<Wei> => signer.getBalance().then((data) => new Wei(data))
}

export function useTokenBalance(
  token?: Token,
  address?: string | null,
  library?: any,
  suspense = false
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) {
  const contract = useContract(token?.address, ERC20_ABI)
  const WETHCheck = isWETH(token?.address, token?.chainId)
  const hasLibrary = !!library
  const fetcher = WETHCheck
    ? hasLibrary
      ? getETHBalance(library.getSigner())
      : () => Promise.resolve(new Wei(toBN(0)))
    : getTokenBalance(contract as Contract, token as Token)
  const result = useSWR(
    typeof address === 'string' && token && contract
      ? [address, token.chainId, token.address, DataType.TokenBalance, !!library]
      : null,
    fetcher,
    { suspense }
  )
  useKeepSWRDataLiveAsBlocksArrive(result.mutate)
  return result
}

function getERC1155Balance(contract: Contract, token: Token): (address: string, id: string) => Promise<Wei> {
  return async (address: string, id: string): Promise<Wei> =>
    contract.balanceOf(address, id).then((bal: { toString: () => string }) => new Wei(toBN(bal.toString()), token.decimals))
}

export function useKeepSWRDataLiveAsBlocksArrive(mutate: responseInterface<any, any>['mutate']): void {
  // because we don't care about the referential identity of mutate, just bind it to a ref
  const mutateRef = useRef(mutate)
  useEffect(() => {
    mutateRef.current = mutate
  })
  // then, whenever a new block arrives, trigger a mutation
  const { data } = useBlockNumber()
  useEffect(() => {
    mutateRef.current()
  }, [data])
}

export function useERC1155Balance(
  token?: Token,
  address?: string | null,
  id?: string | null,
  suspense = false
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): responseInterface<Wei, any> {
  const { data: rmm } = useRmmProtocol()
  const contract = useContract(rmm?.connection.addresses.primitiveManager, [
    'function balanceOf(address account, uint256 id) view returns (uint256)',
  ])

  const result = useSWR(
    typeof address === 'string' && typeof id === 'string' && token && contract
      ? [address, id, token.chainId, token.address, DataType.TokenBalance]
      : null,
    getERC1155Balance(contract as Contract, token as Token),
    { suspense }
  )

  useKeepSWRDataLiveAsBlocksArrive(result.mutate)
  return result
}

function getTokenAllowance(contract: Contract, token: Token): (owner: string, spender: string) => Promise<Wei> {
  return async (owner: string, spender: string): Promise<Wei> =>
    contract.allowance(owner, spender).then((balance: BigNumber) => new Wei(balance, token.decimals))
}

export function useTokenAllowance(
  token?: Token,
  owner?: string | null,
  spender?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): responseInterface<Wei, any> {
  const contract = useContract(token?.address, ERC20_ABI)

  const result = useSWR(
    typeof owner === 'string' && typeof spender === 'string' && token && contract
      ? [owner, spender, token.chainId, token.address, DataType.TokenAllowance]
      : null,
    getTokenAllowance(contract as Contract, token as Token)
  )
  useKeepSWRDataLiveAsBlocksArrive(result.mutate)
  return result
}

function getOnchainToken(
  contract: Contract,
  contractBytes32: Contract
): (chainId: number, address: string) => Promise<Token | null> {
  return async (chainId: number, address: string): Promise<Token | null> => {
    const [decimals, symbol, name] = await Promise.all([
      contract.decimals().catch(() => null),
      contract.symbol().catch(() =>
        contractBytes32
          .symbol()
          .then(parseBytes32String)
          .catch(() => 'UNKNOWN')
      ),
      contract.name().catch(() =>
        contractBytes32
          .name()
          .then(parseBytes32String)
          .catch(() => 'Unknown')
      ),
    ])
    return decimals === null ? null : new Token(chainId, address, decimals, symbol, name)
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useOnchainToken(address?: string, suspense = false): responseInterface<Token | null, any> {
  const { chainId } = useActiveWeb3React()
  const contract = useContract(address, ERC20_ABI)
  const contractBytes32 = useContract(address, ERC20_BYTES32)
  return useSWR(
    typeof chainId === 'number' && typeof address === 'string' && contract && contractBytes32
      ? [chainId, address, DataType.Token]
      : null,
    getOnchainToken(contract as Contract, contractBytes32 as Contract),
    {
      dedupingInterval: 60 * 1000,
      refreshInterval: 60 * 1000,
      suspense,
    }
  )
}

function getNonces(contract: Contract): (account: string) => Promise<string | null> {
  return async (account: string) =>
    contract
      .nonces(account)
      .then((nonce: { toString: () => string }) => nonce.toString())
      .catch((e) => {
        return null
      })
}

export function useEIP2617Contract(tokenAddress?: string): Contract | null {
  return useContract(tokenAddress, EIP_2612, false)
}

export function useNonces(address?: string, account?: string, suspense = false): responseInterface<string | null, any> {
  const contract = useEIP2617Contract(address)
  return useSWR(
    typeof address === 'string' && typeof account === 'string' && contract
      ? [account, 'nonces', account, contract.address]
      : null,
    getNonces(contract as Contract),
    {
      dedupingInterval: 60 * 1000,
      refreshInterval: 60 * 1000,
      suspense,
    }
  )
}

// fix bug, return type
function getFeeData(provider: Web3Provider): (account: string) => Promise<string | undefined> {
  return async () => provider.getFeeData().then((data) => JSON.stringify(data))
}

export function useFeeData(suspense = false): responseInterface<string, any> {
  const { library } = useActiveWeb3React()
  const { data: blocknumber } = useBlockNumber()
  const result = useSWR(library ? ['feeData', blocknumber] : null, getFeeData(library as Web3Provider), {
    dedupingInterval: 60 * 1000,
    refreshInterval: 60 * 1000,
    suspense,
  })

  useKeepSWRDataLiveAsBlocksArrive(result.mutate)
  return result ? result : ('' as any)
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean) {
  return useContract(ENS_REGISTRAR_ADDRESSES, ENS_ABI, withSignerIfPossible)
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean) {
  return useContract(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}
