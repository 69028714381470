import { createStitches } from '@stitches/react'
import {
  teal,
  indigo,
  grass,
  crimson,
  amber,
  slate,
  tealDark,
  indigoDark,
  grassDark,
  crimsonDark,
  amberDark,
  slateDark,
} from '@radix-ui/colors'
import type * as Stitches from '@stitches/react'
import type { CSSProperties } from 'react'
import { createBreakpoint } from 'react-use'

export type { VariantProps } from '@stitches/react'
import type * as StyledComponent from '@stitches/react/types/styled-component'

export type CSS = Stitches.CSS<typeof config>
export type ColorProperties = keyof typeof theme['colors']
// export type BetterVariantProps<Component extends {[key: symbol | string]: any}> = Component[$$StyledComponentProps]
export type Prefixed<K extends string, T> = `${K}${Extract<T, boolean | number | string>}`
export type BetterVariantProps<Component extends { [key: symbol | string]: any }> = StyledComponent.TransformProps<
  Component[StyledComponent.$$StyledComponentProps],
  {}
>

export const useBreakpoint = createBreakpoint({ sm: 640, md: 768, lg: 1024, xl: 1200 })

export const mapTheme = <T extends keyof typeof theme>(themeProp: T, mapFn: (size: string) => CSS) => {
  return Object.entries(theme[themeProp]).reduce(
    (obj, [k, size]) => ({
      ...obj,
      [k]: mapFn(size.value),
    }),
    {}
  ) as Record<keyof typeof theme[T], CSS>
}

export const createInternalColors = (
  color: 'primary' | 'accent' | 'warning' | 'danger' | 'success' | 'neutral',
  internalColorKey = '$$color'
) => {
  const c = (n) => theme.colors[color + String(n)]
  return [...new Array(12)].reduce((obj, _, i) => {
    obj[`${internalColorKey}${i + 1}`] = c(i + 1)
    return obj
  }, {})
}

// TODO - move to tokens file
type shadowtoken = {
  color: string
  type?: string
  x: string | number
  y: string | number
  blur: string | number
  spread: string | number
}

const toPx = (v: string | number) => {
  v = String(v)
  if (v.endsWith('px')) return v
  if (v === '0') return v
  return v + 'px'
}
export const makeShadow = (tokens: shadowtoken[]) =>
  tokens.map((t) => `${toPx(t.x)} ${toPx(t.y)} ${toPx(t.blur)} ${toPx(t.spread)} ${t.color}`).join(', ')

const makeBasicShadows = (prefix = '', baseColor, mainColor) => {
  const baseElevation = {
    color: baseColor, //'#0000000d',
    x: '0',
    y: '0',
    blur: '1',
    spread: '0',
  }
  return [1, 2, 3, 4, 5].reduce(
    (obj, n) => ({
      ...obj,
      [prefix + n]: makeShadow([
        baseElevation,
        {
          color: mainColor, //'#0000001a',
          x: '0',
          y: 2 * n,
          blur: 4 * n,
          spread: '0',
        },
      ]),
    }),
    {}
  )
}

export const basicShadows = {
  ...makeBasicShadows('l', '#0000000d', '#0000001a'),
  ...makeBasicShadows('d', '#000000e6', '#000000'),
}

export const { styled, css, theme, createTheme, getCssText, globalCss, keyframes, config, reset } = createStitches({
  theme: {
    colors: {
      ...teal,
      ...indigo,
      ...slate,
      ...grass,
      ...crimson,
      ...amber,

      slate0: 'white',

      primary1: '$teal1',
      primary2: '$teal2',
      primary3: '$teal3',
      primary4: '$teal4',
      primary5: '$teal5',
      primary6: '$teal6',
      primary7: '$teal7',
      primary8: '$teal8',
      primary9: '$teal9',
      primary10: '$teal10',
      primary11: '$teal11',
      primary12: '$teal12',

      accent1: '$indigo1',
      accent2: '$indigo2',
      accent3: '$indigo3',
      accent4: '$indigo4',
      accent5: '$indigo5',
      accent6: '$indigo6',
      accent7: '$indigo7',
      accent8: '$indigo8',
      accent9: '$indigo9',
      accent10: '$indigo10',
      accent11: '$indigo11',
      accent12: '$indigo12',

      success1: '$grass1',
      success2: '$grass2',
      success3: '$grass3',
      success4: '$grass4',
      success5: '$grass5',
      success6: '$grass6',
      success7: '$grass7',
      success8: '$grass8',
      success9: '$grass9',
      success10: '$grass10',
      success11: '$grass11',
      success12: '$grass12',

      warning1: '$amber1',
      warning2: '$amber2',
      warning3: '$amber3',
      warning4: '$amber4',
      warning5: '$amber5',
      warning6: '$amber6',
      warning7: '$amber7',
      warning8: '$amber8',
      warning9: '$amber9',
      warning10: '$amber10',
      warning11: '$amber11',
      warning12: '$amber12',

      danger1: '$crimson1',
      danger2: '$crimson2',
      danger3: '$crimson3',
      danger4: '$crimson4',
      danger5: '$crimson5',
      danger6: '$crimson6',
      danger7: '$crimson7',
      danger8: '$crimson8',
      danger9: '$crimson9',
      danger10: '$crimson10',
      danger11: '$crimson11',
      danger12: '$crimson12',

      neutral0: '$slate0',
      neutral1: '$slate1',
      neutral2: '$slate2',
      neutral3: '$slate3',
      neutral4: '$slate4',
      neutral5: '$slate5',
      neutral6: '$slate6',
      neutral7: '$slate7',
      neutral8: '$slate8',
      neutral9: '$slate9',
      neutral10: '$slate10',
      neutral11: '$slate11',
      neutral12: '$slate12',

      hiContrast: '$slate12',
      loContrast: '$slate11',
      canvas: 'hsl(0 0% 93%)',
      panel: '$slate1',
      transparentPanel: 'hsl(0 0% 0% / 97%)',
      shadowLight: 'hsl(206 22% 7% / 35%)',
      shadowDark: 'hsl(206 22% 7% / 20%)',
    },
    shadows: {
      ...basicShadows,
      ...makeBasicShadows('', '#0000000d', '#0000001a'),
    },
    fonts: {
      sans: 'Suisse Intl, sans-serif',
    },
    space: {
      '0': '0',
      '0_5': '0.125rem',
      '1': '0.25rem',
      '1_5': '0.325rem',
      '2': '0.5rem',
      '2_5': '0.625rem',
      '3': '0.75rem',
      '3_5': '0.825rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem',
      '11': '2.75rem',
      '12': '3rem',
      '14': '3.5rem',
      '16': '4rem',
      '20': '5rem',
      '24': '6rem',
      '32': '8rem',
      '40': '10rem',
      '48': '12rem',
      '56': '14rem',
      '64': '16rem',
    },
    fontSizes: {
      base: '1rem',
      '2xs': '0.625rem', //10
      xs: '0.75rem', //12
      sm: '0.875rem', //14
      md: '1rem', //16
      lg: '1.125rem', //18
      xl: '1.25rem', //20
      '2xl': '1.625rem', //26
      '3xl': '2.125rem', //34
      '4xl': '3.625rem', //58
    },
    lineHeights: {
      none: '16px',
      tight: '20px',
      snug: '22px',
      normal: '24px',
      relaxed: '26px',
      loose: '32px',
    },
    sizes: {
      '0': '0',
      '0_5': '0.125rem',
      '1': '0.25rem',
      '1_5': '0.325rem',
      '2': '0.5rem',
      '2_5': '0.625rem',
      '3': '0.75rem',
      '3_5': '0.825rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem',
      '11': '2.75rem',
      '12': '3rem',
      '14': '3.5rem',
      '16': '4rem',
      '20': '5rem',
      '24': '6rem',
      '32': '8rem',
      '40': '10rem',
      '48': '12rem',
      '56': '14rem',
      '64': '16rem',
    },
    radii: {
      base: '8px',
      sm: '4px',
      md: '8px',
      lg: '12px',
      xl: '16px',
      round: '50%',
      pill: '9999px',
    },
    zIndices: {
      1: '100',
      2: '200',
      3: '300',
      4: '400',
      max: '999',
    },
  },
  media: {
    sm: '(min-width: 640px)',
    md: '(min-width: 768px)',
    lg: '(min-width: 1024px)',
    xl: '(min-width: 1200px)',
    '-sm': '(max-width: 639px)',
    '-md': '(max-width: 767px)',
    '-lg': '(max-width: 1023px)',
    '-xl': '(max-width: 1199px)',
    motion: '(prefers-reduced-motion)',
    hover: '(any-hover: hover)',
    dark: '(prefers-color-scheme: dark)',
    light: '(prefers-color-scheme: light)',
  },
  utils: {
    bg: (value: Stitches.PropertyValue<'background'>) => ({
      background: value,
    }),

    p: (value: Stitches.PropertyValue<'padding'>) => ({
      padding: value,
    }),
    pt: (value: Stitches.PropertyValue<'paddingTop'>) => ({
      paddingTop: value,
    }),
    pr: (value: Stitches.PropertyValue<'paddingRight'>) => ({
      paddingRight: value,
    }),
    pb: (value: Stitches.PropertyValue<'paddingBottom'>) => ({
      paddingBottom: value,
    }),
    pl: (value: Stitches.PropertyValue<'paddingLeft'>) => ({
      paddingLeft: value,
    }),
    px: (value: Stitches.PropertyValue<'paddingLeft'>) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    py: (value: Stitches.PropertyValue<'paddingTop'>) => ({
      paddingTop: value,
      paddingBottom: value,
    }),

    m: (value: Stitches.PropertyValue<'margin'>) => ({
      margin: value,
    }),
    mt: (value: Stitches.PropertyValue<'marginTop'>) => ({
      marginTop: value,
    }),
    mr: (value: Stitches.PropertyValue<'marginRight'>) => ({
      marginRight: value,
    }),
    mb: (value: Stitches.PropertyValue<'marginBottom'>) => ({
      marginBottom: value,
    }),
    ml: (value: Stitches.PropertyValue<'marginLeft'>) => ({
      marginLeft: value,
    }),
    mx: (value: Stitches.PropertyValue<'marginLeft'>) => ({
      marginLeft: value,
      marginRight: value,
    }),
    my: (value: Stitches.PropertyValue<'marginTop'>) => ({
      marginTop: value,
      marginBottom: value,
    }),

    ta: (value: Stitches.PropertyValue<'textAlign'>) => ({ textAlign: value }),

    fd: (value: Stitches.PropertyValue<'flexDirection'>) => ({ flexDirection: value }),
    fw: (value: Stitches.PropertyValue<'flexWrap'>) => ({ flexWrap: value }),

    ai: (value: Stitches.PropertyValue<'alignItems'>) => ({ alignItems: value }),
    ac: (value: Stitches.PropertyValue<'alignContent'>) => ({ alignContent: value }),
    jc: (value: Stitches.PropertyValue<'justifyContent'>) => ({ justifyContent: value }),
    as: (value: Stitches.PropertyValue<'alignSelf'>) => ({ alignSelf: value }),
    fg: (value: Stitches.PropertyValue<'flexGrow'>) => ({ flexGrow: value }),
    fs: (value: Stitches.PropertyValue<'flexShrink'>) => ({ flexShrink: value }),
    fb: (value: Stitches.PropertyValue<'flexBasis'>) => ({ flexBasis: value }),

    bc: (value: Stitches.PropertyValue<'backgroundColor'>) => ({
      backgroundColor: value,
    }),

    rounded: (value: Stitches.PropertyValue<'borderRadius'>) => ({
      borderRadius: value,
    }),
    br: (value: Stitches.PropertyValue<'borderRadius'>) => ({
      borderRadius: value,
    }),
    btrr: (value: Stitches.PropertyValue<'borderTopRightRadius'>) => ({
      borderTopRightRadius: value,
    }),
    bbrr: (value: Stitches.PropertyValue<'borderBottomRightRadius'>) => ({
      borderBottomRightRadius: value,
    }),
    bblr: (value: Stitches.PropertyValue<'borderBottomLeftRadius'>) => ({
      borderBottomLeftRadius: value,
    }),
    btlr: (value: Stitches.PropertyValue<'borderTopLeftRadius'>) => ({
      borderTopLeftRadius: value,
    }),

    bs: (value: Stitches.PropertyValue<'boxShadow'>) => ({ boxShadow: value }),

    lh: (value: Stitches.PropertyValue<'lineHeight'>) => ({ lineHeight: value }),

    ox: (value: Stitches.PropertyValue<'overflowX'>) => ({ overflowX: value }),
    oy: (value: Stitches.PropertyValue<'overflowY'>) => ({ overflowY: value }),

    pe: (value: Stitches.PropertyValue<'pointerEvents'>) => ({ pointerEvents: value }),
    us: (value: Stitches.PropertyValue<'userSelect'>) => ({
      MozUserSelect: value,
      msUserSelect: value,
      WebkitUserSelect: value,
      userSelect: value,
    }),

    usa: (value: Stitches.PropertyValue<'userSelect'>) => ({
      MozUserSelect: value,
      msUserSelect: value,
      WebkitUserSelect: value,
      userSelect: value,
      MozAppearance: value,
      WebkitAppearance: value,
      appearance: value,
    }),

    userSelect: (value: Stitches.PropertyValue<'userSelect'>) => ({
      MozUserSelect: value,
      msUserSelect: value,
      WebkitUserSelect: value,
      userSelect: value,
    }),

    size: (value: Stitches.PropertyValue<'width'>) => ({
      width: value,
      height: value,
    }),

    appearance: (value: Stitches.PropertyValue<'appearance'>) => ({
      MozAppearance: value,
      WebkitAppearance: value,
      appearance: value,
    }),
    backgroundClip: (value: Stitches.PropertyValue<'backgroundClip'>) => ({
      WebkitBackgroundClip: value,
      backgroundClip: value,
    }),

    // TODO: darkMode and lightMode that pass in [`.${myTheme} &`]: { ...whatever }

    // Does this work? Answer: Sorta, just hard to get types to work right
    hvr: (value: any) => ({
      '@hover': {
        '&:hover': value,
      },
    }),
    actv: (value: any) => ({
      '&:active': value,
    }),
    fcs: (value: any) => ({
      '&:focus': value,
    }),
  },
})

export const darkTheme = createTheme('dark-theme', {
  colors: {
    ...tealDark,
    ...indigoDark,
    ...slateDark,
    ...grassDark,
    ...crimsonDark,
    ...amberDark,

    slate0: 'hsl(200deg 7% 4%)',
    primary1: '$teal1',
    primary2: '$teal2',
    primary3: '$teal3',
    primary4: '$teal4',
    primary5: '$teal5',
    primary6: '$teal6',
    primary7: '$teal7',
    primary8: '$teal8',
    primary9: '$teal9',
    primary10: '$teal10',
    primary11: '$teal11',
    primary12: '$teal12',

    accent1: '$indigo1',
    accent2: '$indigo2',
    accent3: '$indigo3',
    accent4: '$indigo4',
    accent5: '$indigo5',
    accent6: '$indigo6',
    accent7: '$indigo7',
    accent8: '$indigo8',
    accent9: '$indigo9',
    accent10: '$indigo10',
    accent11: '$indigo11',
    accent12: '$indigo12',

    success1: '$grass1',
    success2: '$grass2',
    success3: '$grass3',
    success4: '$grass4',
    success5: '$grass5',
    success6: '$grass6',
    success7: '$grass7',
    success8: '$grass8',
    success9: '$grass9',
    success10: '$grass10',
    success11: '$grass11',
    success12: '$grass12',

    warning1: '$amber1',
    warning2: '$amber2',
    warning3: '$amber3',
    warning4: '$amber4',
    warning5: '$amber5',
    warning6: '$amber6',
    warning7: '$amber7',
    warning8: '$amber8',
    warning9: '$amber9',
    warning10: '$amber10',
    warning11: '$amber11',
    warning12: '$amber12',

    danger1: '$crimson1',
    danger2: '$crimson2',
    danger3: '$crimson3',
    danger4: '$crimson4',
    danger5: '$crimson5',
    danger6: '$crimson6',
    danger7: '$crimson7',
    danger8: '$crimson8',
    danger9: '$crimson9',
    danger10: '$crimson10',
    danger11: '$crimson11',
    danger12: '$crimson12',

    neutral0: '$slate0',
    neutral1: '$slate1',
    neutral2: '$slate2',
    neutral3: '$slate3',
    neutral4: '$slate4',
    neutral5: '$slate5',
    neutral6: '$slate6',
    neutral7: '$slate7',
    neutral8: '$slate8',
    neutral9: '$slate9',
    neutral10: '$slate10',
    neutral11: '$slate11',
    neutral12: '$slate12',

    hiContrast: '$slate12',
    loContrast: '$slate11',
    canvas: 'hsl(0 0% 15%)',
    panel: '$slate3',
    transparentPanel: 'hsl(0 100% 100% / 97%)',
    shadowLight: 'hsl(206 22% 7% / 35%)',
    shadowDark: 'hsl(206 22% 7% / 20%)',
  },
  shadows: {
    ...basicShadows,
    ...makeBasicShadows('', '#000000e6', '#000000'),
  },
})
