import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState } from '../index'
import { setChainSelectModal, setExpiryModal, setIncreaseModal, setMarketModal, setRemoveModal, setWalletModal } from './actions'
import { ModalState } from './reducer'

export const useModal = (): ModalState => {
  const state = useSelector<AppState, AppState['modal']>((state) => state.modal)
  return state
}

export const useOpenWalletModal = (): (() => void) => {
  const dispatch = useDispatch<AppDispatch>()
  return useCallback(() => {
    dispatch(setWalletModal(true))
  }, [dispatch])
}

export const useHideWalletModal = (): (() => void) => {
  const dispatch = useDispatch<AppDispatch>()
  return useCallback(() => {
    dispatch(setWalletModal(false))
  }, [dispatch])
}


export const useOpenMarketModal = (): (() => void) => {
  const dispatch = useDispatch<AppDispatch>()
  return useCallback(() => {
    dispatch(setMarketModal(true))
  }, [dispatch])
}

export const useHideMarketModal = (): (() => void) => {
  const dispatch = useDispatch<AppDispatch>()
  return useCallback(() => {
    dispatch(setMarketModal(false))
  }, [dispatch])
}

export const useOpenExpiryModal = (): (() => void) => {
  const dispatch = useDispatch<AppDispatch>()
  return useCallback(() => {
    dispatch(setExpiryModal(true))
  }, [dispatch])
}

export const useHideExpiryModal = (): (() => void) => {
  const dispatch = useDispatch<AppDispatch>()
  return useCallback(() => {
    dispatch(setExpiryModal(false))
  }, [dispatch])
}

export const useOpenRemoveModal = (): (() => void) => {
  const dispatch = useDispatch<AppDispatch>()
  return useCallback(() => {
    dispatch(setRemoveModal(true))
  }, [dispatch])
}

export const useHideRemoveModal = (): (() => void) => {
  const dispatch = useDispatch<AppDispatch>()
  return useCallback(() => {
    dispatch(setRemoveModal(false))
  }, [dispatch])
}

export const useOpenIncreaseModal = (): (() => void) => {
  const dispatch = useDispatch<AppDispatch>()
  return useCallback(() => {
    dispatch(setIncreaseModal(true))
  }, [dispatch])
}

export const useHideIncreaseModal = (): (() => void) => {
  const dispatch = useDispatch<AppDispatch>()
  return useCallback(() => {
    dispatch(setIncreaseModal(false))
  }, [dispatch])
}

export const useOpenChainSelectModal = (): (() => void) => {
  const dispatch = useDispatch<AppDispatch>()
  return useCallback(() => {
    dispatch(setChainSelectModal(true))
  }, [dispatch])
}

export const useHideChainSelectModal = (): (() => void) => {
  const dispatch = useDispatch<AppDispatch>()
  return useCallback(() => {
    dispatch(setChainSelectModal(false))
  }, [dispatch])
}
