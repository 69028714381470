import Deployments from '../abis/deployments.json'

export const USDC_LOGO_URI = 'https://tokens.1inch.exchange/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png'
export const USDC_BACKGROUND_COLOR = '#2775CA'

export const USDC_METADATA = {
  name: 'USDCoin',
  symbol: 'USDC',
  decimals: 6,
  logoURI: USDC_LOGO_URI,
  backgroundColor: USDC_BACKGROUND_COLOR,
}

export const MAINNET_QUOTE_TOKENS = [{ address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48', chainId: 1, ...USDC_METADATA }]

export const ROPSTEN_QUOTE_TOKENS = !Deployments[3]
  ? []
  : [
      {
        address: Deployments[3].ropsten.contracts.USDC.address,
        chainId: 3,
        ...USDC_METADATA,
      },
    ]

export const RINKEBY_QUOTE_TOKENS = !Deployments[4]
  ? []
  : [
      {
        address: '0x522064c1EafFEd8617BE64137f66A71D6C5c9aA3',
        chainId: 4,
        ...USDC_METADATA,
      },
    ]

export const GOERLI_QUOTE_TOKENS = !Deployments[5]
  ? []
  : [
      {
        address: Deployments[5].goerli.contracts.USDC.address,
        chainId: 5,
        ...USDC_METADATA,
      },
    ]

export const KOVAN_QUOTE_TOKENS = !Deployments[42]
  ? []
  : [
      {
        address: Deployments[42].kovan.contracts.USDC.address,
        chainId: 42,
        ...USDC_METADATA,
      },
    ]

export const ARBITRUM_ONE_QUOTE_TOKENS = !Deployments[42161]
  ? []
  : [
      {
        address: Deployments[42161].arbtriumone.contracts.USDC.address,
        chainId: 42161,
        ...USDC_METADATA,
      },
    ]

export const ARBITRUM_RINKEBY_QUOTE_TOKENS = !Deployments[421611]
  ? []
  : [
      {
        address: Deployments[421611].arbitrumrinkeby.contracts.USDC.address,
        chainId: 421611,
        ...USDC_METADATA,
      },
    ]

export const OPTIMISM_QUOTE_TOKENS = !Deployments[10]
  ? []
  : [
      {
        address: Deployments[10].optimism.contracts.USDC.address,
        chainId: 10,
        ...USDC_METADATA,
      },
    ]

export const OPTIMISTIC_KOVAN_QUOTE_TOKENS = !Deployments[69]
  ? []
  : [
      {
        address: Deployments[69].optimistickovan.contracts.USDC.address,
        chainId: 69,
        ...USDC_METADATA,
      },
    ]

export const QUOTE_TOKENS = {
  1: MAINNET_QUOTE_TOKENS,
  3: ROPSTEN_QUOTE_TOKENS,
  4: RINKEBY_QUOTE_TOKENS,
  5: GOERLI_QUOTE_TOKENS,
  10: OPTIMISM_QUOTE_TOKENS,
  42: KOVAN_QUOTE_TOKENS,
  69: OPTIMISTIC_KOVAN_QUOTE_TOKENS,
  42161: ARBITRUM_ONE_QUOTE_TOKENS,
  421611: ARBITRUM_RINKEBY_QUOTE_TOKENS,
}
