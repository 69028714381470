import { useEffect } from 'react'
import { useActiveWeb3React } from '@/hooks/web3'
import { useNavigate } from '@/hooks/useNavigate'
import { ALL_SUPPORTED_CHAIN_IDS } from '@/constants/chains'
import { useDispatch, useSelector } from 'react-redux'
import { setLastUpdatedChainLocation } from '../user/actions'
import { AppState } from '..'

export default function ChainIdUpdater(): null {
  const { chainId, library } = useActiveWeb3React()
  const navigate = useNavigate()
  const routeChainId = navigate.getChainIdFromRoute(chainId)
  const dispatch = useDispatch()
  const lastUpdatedChainLocation = useSelector<AppState, string>((state) => state.user.lastUpdatedChainLocation)

  useEffect(() => {
    if (!ALL_SUPPORTED_CHAIN_IDS.includes(routeChainId)) {
      navigate.updateChainIdInRoute(chainId)
      dispatch(setLastUpdatedChainLocation('route'))
      return
    }
    if (chainId && routeChainId && chainId !== routeChainId && library && lastUpdatedChainLocation === 'route') {
      library
        .send('wallet_switchEthereumChain', [{ chainId: '0x' + routeChainId }])
        .then(() => {
          dispatch(setLastUpdatedChainLocation('route'))
          navigate.updateChainIdInRoute(routeChainId)
        })
        .catch(() => {
          dispatch(setLastUpdatedChainLocation('route'))
          navigate.updateChainIdInRoute(chainId)
        })
      return
    }
  }, [chainId, routeChainId])

  return null
}
