import React, { useMemo, useState } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import HamburgerMenuIcon from './HamburgerMenuIcon'
// import MobileMenu from '@/components/Topbar/MobileMenu'
// import DesktopMenu from '@/components/Topbar/DesktopMenu'

import { PreferredChainModalButton } from '../Page/PreferredChainModal'
import Faucet from './Faucet'
import WalletModalButton from './WalletModal'
import StrikeTokenSettings from './StrikeTokenPopover'
import SettingsModalAndTrigger from '../Settings'

import Box from '@/ui/Box'
import Text from '@/ui/Text'
import { styled } from '@/ui/theme'
import { useActiveWeb3React } from '@/hooks/web3'

import * as Select from '@radix-ui/react-select'
import Button from '@/ui/Button'
import Card from '@/ui/Card'
import Icon from '@/ui/Icon'
import LinksDropdown from './LinksDropdown'
export interface MenuProps {
  active: boolean
  openSettingsModal: () => void
}

export interface AppLink {
  label: string
  value: string
  subLabel: string
  disabled: boolean
}

const appLinks: AppLink[] = [
  {
    label: 'Liquidity',
    value: 'liquidity',
    subLabel: 'Alpha',
    disabled: false,
  },
  {
    label: 'Swap',
    value: 'swap',
    subLabel: 'Coming Soon',
    disabled: true,
  },
  {
    label: 'Analytics',
    value: 'analytics',
    subLabel: 'Coming Soon',
    disabled: true,
  },
]

const customSelectItem = (item: AppLink) => {
  return (
    <Select.Item value={item.value} disabled={item.disabled} key={item.value} style={{ outline: 'none' }}>
      <Box
        css={{
          p: '$2',
          cursor: item.disabled ? '' : 'pointer',
          userSelect: item.disabled ? 'none' : '',
          borderRadius: '$sm',
          '&:hover': {
            backgroundColor: item.disabled ? '' : '$accent8',
          },
        }}
      >
        <Box
          css={{
            display: 'flex',
            alignItems: 'center',
            color: item.disabled ? '$neutral10' : '$neutral12',
          }}
        >
          <Select.ItemText>
            <Text size="sm">{item.label}</Text>
          </Select.ItemText>
          <Text
            size={'xs'}
            css={{
              ml: '$2',
              backgroundColor: item.disabled ? '$neutral5' : '$accent5',
              px: '$2',
              borderRadius: '$xl',
              color: item.disabled ? '$neutral9' : '$accent11',
            }}
          >
            {item.subLabel}
          </Text>
        </Box>
      </Box>
    </Select.Item>
  )
}

const MenuWrapper = styled(Box, {
  '@-md': {
    position: 'absolute',
    top: 68,
    right: 0,
    display: 'flex',
    pr: '$4',
    borderBottom: '1px solid $slate4',
    width: '100%',
    transition: 'max-height 0.2s ease-in-out',
    overflow: 'hidden',
    backgroundColor: '$slate1',
    zIndex: '$4',
    justifyContent: 'flex-end',
  },
})

const TopBar: React.FC = () => {
  const { account, active } = useActiveWeb3React()
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <>
      <Box>
        <Box css={{backgroundColor: '$accent9', p: '$2', textAlign: 'center'}}>
          <Text css={{color:'$neutral12'}}>RMM-01 deposits are no longer supported. Please withdraw all liquidity remaining in RMM-01 positions.</Text></Box>
        <Box as="header" css={{ width: '100%' }}>
          <Box as="nav" css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start', p: '$4', mx: 'auto' }}>
            <Box css={{ display: 'flex', alignItems: 'center' }}>
              <Link href="/">
                <Box css={{ display: 'flex', alignItems: 'center', pl: '$1', mr: '$6', cursor: 'pointer' }}>
                  <Image src="/topbar-logo.svg" height={35} width={30} layout="fixed" />
                </Box>
              </Link>
              <Select.Root value="liquidity">
                <Select.Trigger asChild>
                  <Button variant="outline" css={{ px: '$2' }} aria-label="app select">
                    <Box
                      css={{
                        height: '100%',
                        minWidth: '150px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      color="hiContrast"
                    >
                      <Select.Value />
                      {/* TODO: Remove Static Alpha Tag */}
                      <Text
                        size="xs"
                        css={{
                          backgroundColor: '$accent5',
                          color: '$accent11',
                          px: '$2',
                          borderRadius: '$xl',
                          ml: '$2',
                        }}
                      >
                        Alpha
                      </Text>
                    </Box>
                    <Box css={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                      <Icon icon="ChevronDownIcon" height={16} width={16} />
                    </Box>
                  </Button>
                </Select.Trigger>

                <Select.Content>
                  <Card css={{ backgroundColor: '$neutral3', px: '$2', py: '$1', borderRadius: '$md' }}>
                    <Select.ScrollUpButton />
                    <Select.Viewport>{appLinks.map((appLink) => customSelectItem(appLink))}</Select.Viewport>
                    <Select.ScrollDownButton />
                  </Card>
                </Select.Content>
              </Select.Root>
            </Box>
            <MenuWrapper
              css={{
                '@-md': {
                  maxHeight: mobileMenuOpen ? 350 : 0,
                },
              }}
            >
              <Box
                as="ul"
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                  gap: '$2',
                  '@-md': {
                    gap: '$2',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    mb: '$2',
                  },
                }}
              >
                {/* {active && (
                  <>
                    <Faucet />
                    <StrikeTokenSettings />
                  </>
                )} */}
                {account ? (
                  <Box as="li">
                    <PreferredChainModalButton />
                  </Box>
                ) : (
                  <Box as="li">
                    <WalletModalButton />
                  </Box>
                )}
                <Box as="li">
                  <SettingsModalAndTrigger />
                </Box>
                <Box as="li">
                  <LinksDropdown />
                </Box>
              </Box>
            </MenuWrapper>
            <Box css={{ '@md': { display: 'none' } }}>
              <HamburgerMenuIcon open={mobileMenuOpen} handleClick={() => setMobileMenuOpen(!mobileMenuOpen)} />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default TopBar
