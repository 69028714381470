import React, { useState, useMemo } from 'react'
import Modal from '@/ui/Modal'
import TokenListSettings from './TokenListSettings'
import QuoteTokenSettings from './QuoteTokenSettings'
import NotificationSettings from './NotificationSettings'
import PositionSettings from './PositionSettings'
import ApprovalSettings from './ApprovalSettings'
import Text from '@/ui/Text'
import Box from '@/ui/Box'
import { Button } from '@/ui/Button'
import Icon from '@/ui/Icon'
import compressAddress from '@/utils/compressAddress'
import Jazzicon from 'react-jazzicon'
import useENSName from '@/hooks/useENSName'
import { useActiveWeb3React } from '@/hooks/web3'

const SettingsModalAndTrigger: React.FC = () => {
  const { account } = useActiveWeb3React()
  const ENSName = useENSName(account)

  const [activeTab, setActiveTab] = useState('general')
  const switchTabs = (tabName: string) => {
    setActiveTab(tabName)
  }

  const settingsTabs = {
    general: <PositionSettings />,
    tokenLists: <TokenListSettings />,
    strikeToken: <QuoteTokenSettings />,
    approvals: <ApprovalSettings />,
    notifications: <NotificationSettings />,
  }

  const getSelectedStyle = (tabName: string) => {
    return {
      backgroundColor: activeTab == tabName ? '$accent6' : '$slate2',
      padding: '$2',
      borderRadius: '$sm',
      border: 'none',
      color: activeTab == tabName ? '$neutral12' : '$neutral11',
      '&:hover': { color: 'white' },
      justifyContent: 'flex-start',
    }
  }
  return (
    <Modal>
      <Modal.Trigger asChild>
        <Button variant="outline" css={{ px: '$2' }} aria-label="open settings modal">
          {account && <Jazzicon seed={parseInt(account.slice(2, 10), 16)} diameter={21} />}
          {account && (ENSName ? ENSName : compressAddress(account))}
          <Icon icon="GearIcon" />
        </Button>
      </Modal.Trigger>
      <Modal.Content
        id="settings-modal"
        css={{ width: 700, minHeight: 600, '@-md': { width: '80%' }, '@-sm': { width: '90%' } }}
      >
        <Text size="xl" color="hiContrast" css={{ mb: '$4' }}>
          Settings
        </Text>
        <Box css={{ display: 'flex', '@-sm': { flexDirection: 'column' } }}>
          <Box
            display="flex-col"
            css={{
              backgroundColor: '$neutral2',
              padding: '$3',
              borderRadius: '$md',
              width: 200,
              height: 145,
              '@-sm': { width: '100%' },
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <Button
                css={{ textAlign: 'left', width: '100%', ...getSelectedStyle('general') }}
                onClick={() => switchTabs('general')}
                aria-label="general settings"
              >
                General
              </Button>
            </Box>
            {/*
            <Box css={getSelectedStyle('tokenLists')}>
              <button style={{ textAlign: 'left', width: '100%' }} onClick={() => switchTabs('tokenLists')}>
                Token Lists
              </button>
            </Box>
            <Box css={getSelectedStyle('strikeToken')}>
              <button style={{ textAlign: 'left', width: '100%' }} onClick={() => switchTabs('strikeToken')}>
                Quote Token
              </button>
            </Box>
            */}
            <Box>
              <Button
                css={{ textAlign: 'left', width: '100%', ...getSelectedStyle('approvals') }}
                onClick={() => switchTabs('approvals')}
                aria-label="approval settings"
              >
                Approvals
              </Button>
            </Box>
            <Box>
              <Button
                css={{ textAlign: 'left', width: '100%', ...getSelectedStyle('notifications') }}
                onClick={() => switchTabs('notifications')}
                aria-label="logs settings"
              >
                Logs
              </Button>
            </Box>
          </Box>
          <Box css={{ px: '$3', width: '80%', '@-sm': { width: '100%', mt: '$2' } }}>{settingsTabs[activeTab]}</Box>
        </Box>
      </Modal.Content>
    </Modal>
  )
}

export default SettingsModalAndTrigger
