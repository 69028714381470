import { QUOTE_TOKENS } from '@/constants/tokenLists/quote'
import { useNavigate } from '@/hooks/useNavigate'
import { useActiveWeb3React } from '@/hooks/web3'
import { useUpdateStrikeToken, useUser } from '@/state/user/hooks'
import { Token } from '@/state/user/reducer'
import Box from '@/ui/Box'
import Label from '@/ui/FormControl/Label'
import Text from '@/ui/Text'
import Image from 'next/image'
import React, { useEffect } from 'react'

const QuoteTokenSettings = () => {
  const { chainId } = useActiveWeb3React()
  const navigate = useNavigate()
  const routeChainId = navigate.getChainIdFromRoute(chainId)
  const tokens = QUOTE_TOKENS[routeChainId]

  const updateStrikeToken = useUpdateStrikeToken()
  const { strikeToken } = useUser()

  const selectStrikeToken = (token: Token): void => {
    updateStrikeToken(token, chainId ?? 4)
  }

  useEffect(() => {
    if (chainId && routeChainId) {
      updateStrikeToken(tokens[0], chainId)
    }
  }, [strikeToken, chainId])

  return (
    <Box css={{ display: 'flex', flexDirection: 'column' }}>
      <Text size="md" color="hiContrast">
        Quote Token
      </Text>
      <Text size="xs" color="hiContrast" css={{ mb: '$3' }}>
        Manage the Quote Token to use within the platform
      </Text>
      {tokens.length > 0 &&
        tokens.map((token) => (
          <Box
            css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', pb: '$2' }}
            key={token.address}
          >
            <Box css={{ display: 'flex', alignItems: 'center' }}>
              <Image width={32} height={32} src={token.logoURI}></Image>
              <Text inline css={{ pl: '$4' }} color="hiContrast" size="sm">
                {token.symbol}
              </Text>
            </Box>
            <input
              className="hidden"
              id={token.symbol}
              type="radio"
              name="selectedStrike"
              checked={token.symbol === strikeToken?.symbol}
              onClick={() => selectStrikeToken(token)}
              onChange={() => selectStrikeToken(token)}
            ></input>
            <Label css={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }} htmlFor={token.symbol}>
              <Image
                width={24}
                height={24}
                src={token?.symbol === strikeToken?.symbol ? '/checked.svg' : '/unchecked.svg'}
              />
            </Label>
          </Box>
        ))}
    </Box>
  )
}
export default QuoteTokenSettings
