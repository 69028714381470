import invariant from 'tiny-invariant'
import { getAddress, isAddress } from '@ethersproject/address'
import { WETH9 } from '@uniswap/sdk-core'
import { SUPPORTED_CURRENCY_MAPPING } from '@/constants/tokens'
import { isWETH } from './isWETH'
import { DEFAULT_CHAIN_ID } from '../constants'

/**
 * @param ADD The Add liquidity page
 * @param MANAGE The Manage liquidity page
 */
export enum SupportedBaseSlugs {
  ADD = '/liquidity/add',
  MANAGE = '/liquidity/manage',
}

/**
 * @param ETH Ether native currency
 * @param USDC USD Coin
 */
export enum SupportedCurrencySlugs {
  ETH = 'ETH',
  USDC = 'USDC',
}

export const DEFAULT_ADD_SLUG = `${SupportedBaseSlugs.ADD}/ETH/0x522064c1EafFEd8617BE64137f66A71D6C5c9aA3`

/**
 * @notice Constructs a url for a view into managing the position
 * @param account Address to view portfolio of
 * @param poolId Pool to view position of
 * @returns valid url slug for the portfolio view
 */
interface GetManageSlugParams {
  chainId: number | string
  asset: string
  quote: string
  account: string
  poolId: string
}
export function getManageSlug({ chainId, asset, quote, account, poolId }: GetManageSlugParams): string {
  invariant(isAddress(asset), `invalid asset address: ${asset}`)
  invariant(isAddress(quote), `invalid quote address: ${quote}`)
  invariant(isAddress(account), `invalid account address: ${account}`)
  invariant(poolId.substr(0, 2) === '0x', `invalid poolId: ${poolId}`)
  return `${SupportedBaseSlugs.MANAGE}/${chainId}/${getAddress(account)}/${getAddress(asset)}/${getAddress(quote)}/${poolId}`
}

/**
 * @returns valid url slug to add liquidity to a pool with params
 */
interface GetAddSlugProps {
  chainId?: number | string
  asset: string
  quote: string
  maturity?: string
  strike?: string
  sigma?: string
  gamma?: string
}
export function getAddSlug({ chainId = DEFAULT_CHAIN_ID, asset, quote, maturity, strike, sigma, gamma }: GetAddSlugProps) {
  let slug = `${SupportedBaseSlugs.ADD}/${chainId}/${asset}/${quote}/`
  if (maturity) {
    slug += `${maturity}/`
    if (strike) {
      slug += `${strike}/`
      if (sigma) {
        slug += `${sigma}/`
        if (gamma) slug += `${gamma}/`
      }
    }
  }
  return slug
}

/**
 * @returns valid if the `slug` is in the supported currency slugs enum
 */
export function isValidCurrencySlug(slug: string) {
  return Object.values(SupportedCurrencySlugs).includes(slug?.toUpperCase() as SupportedCurrencySlugs)
}

export function validateAddressFromSlug(address: string, chainId: number): string | undefined {
  if (!address) return undefined
  return isValidCurrencySlug(address)
    ? SUPPORTED_CURRENCY_MAPPING?.[address.toUpperCase() as SupportedCurrencySlugs]?.[chainId]?.address
    : undefined
}
