import { createReducer } from '@reduxjs/toolkit'
import { addTransaction, clearAllTransactions, updateTransaction } from './actions'

import { TransactionKind } from 'src/eth-tx-manager/types'

export function getCurrentTimestamp() {
  return new Date().getTime()
}

export interface TransactionState {
  [chainId: number]: {
    [txHash: string]: TransactionKind
  }
}

export const initialState: TransactionState = {}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(addTransaction, (transactions, { payload: { tx } }) => {
      if (!tx.hash) return transactions

      if (transactions[tx.chainId]?.[tx.hash]) throw Error('Attempted to add existing transaction.')
      const txs = transactions[tx.chainId] ?? {}
      txs[tx.hash] = tx
      transactions[tx.chainId] = txs
      return transactions
    })
    .addCase(clearAllTransactions, (transactions, { payload: { chainId } }) => {
      if (!transactions[chainId]) return
      transactions[chainId] = {}
      return transactions
    })
    .addCase(updateTransaction, (transactions, { payload: { chainId, tx } }) => {
      if (!tx.hash) return
      transactions[chainId][tx.hash] = tx
    })
)
