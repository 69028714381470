const ETHERSCAN_PREFIXES = {
  1: '',
  4: 'rinkeby.',
}

export default function getEtherscanLink(networkId: number, data: string, type: 'TRANSACTION' | 'ADDRESS') {
  const prefix = `https://${ETHERSCAN_PREFIXES[networkId] || ETHERSCAN_PREFIXES[1]}etherscan.io`

  switch (type) {
    case 'TRANSACTION': {
      return `${prefix}/tx/${data}`
    }
    case 'ADDRESS':
    default: {
      return `${prefix}/address/${data}`
    }
  }
}
