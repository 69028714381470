import { Time } from 'web3-units'
import { TransactionReceipt, TransactionResponse, Web3Provider } from '@ethersproject/providers'

import { SerializableTransactionReceipt } from './types'
import fetchAndApplyGasEstimate from '@/utils/fetchAndApplyGasEstimate'
import { TxPayload, TxPayloadWithGasLimit } from '@/utils/sendTransaction'
import { PeripheryManager } from '@primitivefi/rmm-sdk'

export enum TxStatus {
  PREPARED = 'PREPARED',
  PREPARED_WITH_GAS_LIMIT = 'PREPARED_WITH_GAS_LIMIT',
  BROADCASTED = 'BROADCASTED',
  MINED = 'MINED',
  CONFIRMED = 'CONFIRMED',
}

/**
 * @notice Immutable packaged transaction class to handle the different transaction states
 * PREPARED = Contains a payload that is ready to be broadcasted with `sendTransaction`
 * PREPARED WITH GAS LIMIT = Contains a payload with a gas limit applied
 * BROADCASTED = A payload that has been sent with a successful response and tx hash
 * MINED = Transaction that has been broadcasted and has a receipt
 * CONFIRMED = Transaction that has been polled 50 blocks after it has been mined
 */
export class SingleTx {
  public readonly chainId: number
  public readonly payload: TxPayload
  public readonly metadata: Record<string, unknown>

  private readonly _payloadGasLimit: TxPayloadWithGasLimit
  private readonly _hash?: string
  private readonly _addedTime?: number
  private readonly _receipt?: SerializableTransactionReceipt
  private readonly _lastCheckedBlockNumber?: number
  private readonly _confirmedTime?: number
  private readonly _from?: string

  public _method?: string

  /**
   * @returns SingleTx class with a payload that can be prepared or sent
   */
  public static fromPayload(chainId: number, payload: TxPayload, method?: string, metadata?: any): SingleTx {
    return new SingleTx(metadata ?? {}, chainId, payload, method)
  }

  /**
   * @returns SingleTx class with a payload that has a gas limit applied
   */
  public static fromPrepared(chainId: number, payload: TxPayloadWithGasLimit, method?: string, metadata?: any): SingleTx {
    return new SingleTx(metadata ?? {}, chainId, payload, method)
  }

  /**
   * @returns SingleTx class that has been sent to the network at a time `addedTime` with a `hash`
   */
  public static fromBroadcast(
    tx: SingleTx,
    hash: string,
    addedTime: number,
    latestBlockNumber: number,
    from?: string
  ): SingleTx {
    const { chainId, payload, method, metadata } = tx
    return new SingleTx(
      metadata ?? {},
      chainId,
      payload,
      method,
      hash,
      addedTime,
      undefined,
      latestBlockNumber,
      undefined,
      from
    )
  }

  /**
   * @returns SingleTx class that has been checked for a receipt
   */
  public static fromChecked(tx: SingleTx, latestBlockNumber: number): SingleTx {
    const { chainId, payload, hash, addedTime, from, method, metadata } = tx
    return new SingleTx(
      metadata ?? {},
      chainId,
      payload,
      method,
      hash,
      addedTime,
      undefined,
      latestBlockNumber,
      undefined,
      from
    )
  }

  /**
   * @returns SingleTx class that has a `receipt` and `confirmedTime`, after being broadcasted
   */
  public static fromMined(
    tx: SingleTx,
    lastCheckedBlockNumber: number,
    confirmedTime: number,
    receipt?: SerializableTransactionReceipt | undefined
  ): SingleTx {
    if (tx.status !== TxStatus.BROADCASTED)
      throw new Error(`Tx has not been broadcasted yet according to status:  ${tx.status}`)
    const { chainId, payload, hash, addedTime, from, method, metadata } = tx
    return new SingleTx(
      metadata,
      chainId,
      payload,
      method,
      hash,
      addedTime,
      receipt,
      lastCheckedBlockNumber,
      confirmedTime,
      from
    )
  }

  private constructor(
    metadata: Record<string, unknown>,
    chainId: number,
    payload: TxPayload | TxPayloadWithGasLimit,
    method?: string,
    hash?: string,
    addedTime?: number,
    receipt?: SerializableTransactionReceipt,
    lastCheckedBlockNumber?: number,
    confirmedTime?: number,
    from?: string
  ) {
    this.metadata = metadata
    this.chainId = chainId
    this.payload = payload
    if ((payload as TxPayloadWithGasLimit).gasLimit) this._payloadGasLimit = payload as TxPayloadWithGasLimit
    if (hash) this._hash = hash
    if (addedTime) this._addedTime = addedTime
    if (receipt) this._receipt = receipt
    if (lastCheckedBlockNumber) this._lastCheckedBlockNumber = lastCheckedBlockNumber
    if (confirmedTime) this._confirmedTime = confirmedTime
    if (from) this._from = from
    if (method) this._method = method

    Object.freeze(this)
  }

  /**
   * @returns status of this Transaction depending on what state it has
   */
  get status() {
    if (this._receipt) {
      if (this._lastCheckedBlockNumber ? this._lastCheckedBlockNumber - this._receipt.blockNumber > 50 : false)
        return TxStatus.CONFIRMED

      return TxStatus.MINED
    }
    if (this._hash) return TxStatus.BROADCASTED
    if (this._payloadGasLimit) return TxStatus.PREPARED_WITH_GAS_LIMIT
    return TxStatus.PREPARED // default prepared status
  }

  /**
   * @notice The only mutable property
   */
  set method(name: string | undefined) {
    this._method = name
  }
  get method(): string | undefined {
    return this._method
  }
  get payloadGasLimit() {
    return this._payloadGasLimit
  }
  get hash() {
    return this._hash
  }
  get addedTime() {
    return this._addedTime
  }
  get receipt() {
    return this._receipt
  }
  get lastCheckedBlockNumber() {
    return this._lastCheckedBlockNumber
  }
  get confirmedTime() {
    return this._confirmedTime
  }
  get from() {
    return this._from
  }

  /**
   * @returns Prepared with gas limit if successfully fetched
   */
  public async attemptGasEstimation(library: any, gasLimitMultiplier?: number): Promise<SingleTx | undefined> {
    return fetchAndApplyGasEstimate(library, this.payload, gasLimitMultiplier ?? undefined)
      .then((res) => {
        const { payload, success } = res
        if (success) return SingleTx.fromPrepared(this.chainId, payload as TxPayloadWithGasLimit)
        else {
          console.log('gas estimation unsuccessful, attempting call')
          return library
            .call(payload)
            .then((result) => {
              console.log('Successful call after failed gas estimation', payload, result, res?.error)
              return undefined
            })
            .catch((e) => {
              console.log('failed call after gas estimation fail', e)
              const txData = e?.transaction?.data
              console.log('With data', PeripheryManager.INTERFACE.decodeFunctionData('allocate', txData))
              return undefined
            })
        }
      })
      .catch((e) => {
        console.log('caught on calling gas estimation')
        return undefined
      })

    /*  try {
      const { payload } = await fetchAndApplyGasEstimate(library, this.payload, gasLimitMultiplier ?? undefined)
      if (payload.gasLimit) return SingleTx.fromPrepared(this.chainId, payload as TxPayloadWithGasLimit)
    } catch (e) {
      //do nothing
    }
    return this */
  }

  /**
   * @returns Broadcasted SingleTx class
   */
  public async send(library: any): Promise<TransactionResponse> {
    return library.getSigner().sendTransaction(this._payloadGasLimit ?? this.payload)
  }

  /**
   * @returns If a receipt exists, a Mined SingleTx, else a SingleTx with an updated `lastCheckedBlockNumber`
   */
  public async poll(library: any, blockNumber: number): Promise<SingleTx | undefined> {
    if (!this.hash || this.receipt) return undefined
    return library
      .getTransactionReceipt(this.hash)
      .then((receipt: TransactionReceipt) => {
        if (receipt && this.status === TxStatus.BROADCASTED)
          return SingleTx.fromMined(this, receipt.blockNumber, Time.now, receipt)
        else return SingleTx.fromChecked(this, blockNumber)
      })
      .catch((e) => {
        console.log('No receipt found', e)
        return SingleTx.fromChecked(this, blockNumber)
      })
  }

  /**
   * @notice Broadcasts a transaction to the network
   * @param library Connected web3 provider with a signer
   * @param payload Transaction target address, data, and value to send
   */
  static async sendTransaction(library: Web3Provider, payload: TxPayload): Promise<TransactionResponse> {
    return library.getSigner().sendTransaction(payload)
  }

  public static isTransactionRecent(tx: SingleTx): boolean {
    if (!tx?.addedTime) return false
    return Time.now - tx.addedTime < 86_400
  }

  public static fromTransactionResponse(tx: SingleTx, resp: TransactionResponse, latestBlockNumber: number): SingleTx {
    return SingleTx.fromBroadcast(tx, resp.hash, Time.now, latestBlockNumber, resp.from)
  }

  /**
   *
   * @param lastBlockNumber
   * @param singleTx
   * @returns
   */
  public static shouldSync(lastBlockNumber: number, singleTx: SingleTx) {
    // if theres a receipt
    if (singleTx.receipt) return false
    // if the tx has not been sent yet
    if (!singleTx.addedTime) return true
    // if the receipt has not been polled for yet
    if (!singleTx.lastCheckedBlockNumber) return true
    // distance between last check
    const blocksSinceCheck = lastBlockNumber - singleTx.lastCheckedBlockNumber
    // if the last check was during this block
    if (blocksSinceCheck < 1) return false

    const minutesPending = (Time.now - singleTx.addedTime) / 60

    if (minutesPending > 60) {
      // every 10 blocks if pending for longer than an hour
      return blocksSinceCheck > 9
    } else if (minutesPending > 5) {
      // every 3 blocks if pending more than 5 minutes
      return blocksSinceCheck > 2
    } else {
      // otherwise every block
      return true
    }
  }
}
