import React, { ComponentProps, forwardRef } from 'react'
import Button from '@/ui/Button'
import Box from '@/ui/Box'
import Text from '@/ui/Text'
import Icon from '@/ui/Icon'
interface ModalSelectProps extends ComponentProps<typeof Button> {
  dataCy?: string
  value: string
  disabled?: boolean
  className?: string
  onClick?: () => void
}

const ModalSelect = forwardRef<HTMLInputElement, ModalSelectProps>(
  ({ value, children, disabled, onClick, dataCy, ...rest }, ref) => {
    return (
      <Button ref={ref} {...rest} wide={true} disabled={disabled} variant="outline" size="lg" onClick={onClick}>
        <Box css={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Box css={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Box css={{ pr: '$2', display: 'flex', alignItems: 'center' }}>{children}</Box>
            <Box css={{ width: '100%', textAlign: 'left', py: '$2' }}>
              <Text inline size="sm" color="hiContrast" css={{ flexWrap: 'nowrap' }}>
                {value}
              </Text>
            </Box>
          </Box>
          <Box css={{ height: '$11', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Icon icon="ModelLinkIcon" css={{ display: 'block' }} />
          </Box>
        </Box>
      </Button>
    )
  }
)

ModalSelect.displayName = 'ModalSelect'

export default ModalSelect
