import { css } from '@/ui/theme'

export const BaseFieldStyles = css({
  variants: {
    size: {
      sm: {
        px: '$2',
        fontSize: '$2xs',
        height: '$8',
      },
      md: {
        px: '$3',
        fontSize: '$sm',
        height: '$10',
      },
      lg: {
        px: '$4',
        fontSize: '$base',
        height: '$12',
      },
    },
  },
  defaultVariants: {
    size: 'md',
  },
})

const InputStates = {
  hover: {
    borderColor: '$$active',
  },
  focus: {
    outline: '1px solid $$active',
    borderColor: '$$active',
  },
}

export const InputStyles = css(BaseFieldStyles, {
  $$active: '$colors$neutral9',
  $$inactive: '$colors$neutral7',

  position: 'relative',
  minWidth: '0',
  display: 'inline-flex',
  alignItems: 'center',
  borderRadius: '$md',
  transitionProperty: 'background-color,border-color,color,fill,stroke',
  transitionTimingFunction: 'cubic-bezier(.4,0,.2,1)',
  transitionDuration: '.15s',
  border: '1px solid transparent',
  background: 'transparent',
  '&:hover': InputStates.hover,
  '&:focus': InputStates.focus,
  '&::placeholder': {
    color: '$neutral8',
  },
  '&:disabled': {
    cursor: 'default',
    pointerEvents: 'none',
    opacity: 0.6,
  },
  borderColor: '$$inactive',
  '&:read-only': {
    backgroundColor: '$neutral4',
    '&:focus': {
      borderColor: '$colors$neutral7',
    },
  },
  variants: {
    // For dev and debug. Don't actually use these
    forceInputState: InputStates,
    wide: {
      true: {
        width: '100%',
      },
    },
    type: {
      text: {},
      decimal: {},
    },
    size: {
      sm: {
        height: '$8',
      },
      md: {
        height: '$10',
      },
      lg: {
        height: '$12',
      },
    },
    state: {
      valid: {
        $$inactive: '$colors$success7',
        $$active: '$colors$success8',
      },
      warning: {
        $$inactive: '$colors$warning7',
        $$active: '$colors$warning8',
      },
      invalid: {
        $$inactive: '$colors$danger7',
        $$active: '$colors$danger8',
      },
    },
    variant: {
      outline: {},
      unstyled: {
        $$inactive: 'transparent',
        $$active: 'transparent',
      },
    },
  },
  defaultVariants: {
    type: 'text',
    size: 'md',
    variant: 'outline',
  },
})

export const InputDecimalStyles = css(InputStyles, {})

export const InputElementStyles = css(BaseFieldStyles, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: '0',
  zIndex: 2,
})

export const InputAddonStyles = css({})

export const InputGroupStyles = css({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
})
