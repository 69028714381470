import React from 'react'
import { css, mapTheme, styled } from '@/ui/theme/stitches.config'
import * as RadixIcons from '@radix-ui/react-icons'
import { withStyledRef } from '@/ui/theme/utils'

import OpenInternalLinkIcon from './icons/OpenInternalLinkIcon'
import USDCIcon from './icons/USDCIcon'
import ETHIcon from './icons/ETHIcon'

export const StyledIconCss = css({
  variants: {
    size: mapTheme<'fontSizes'>('fontSizes', (s) => ({
      width: s,
      height: s,
    })),
  },
  defaultVariants: {
    size: 'base',
  },
})

const ADDITIONAL_ICONS = {
  OpenInternalLinkIcon,
  USDCIcon,
  ETHIcon,
  ModelLinkIcon: RadixIcons['DotsHorizontalIcon'],
}

export type iconElements = keyof typeof RadixIcons | keyof typeof ADDITIONAL_ICONS

// NOTE: This is kinda a hack. I'd like a more robust version of this at some point, but idk how to get it working with stitches for now.
const ICON_COMPONENT_CACHE: Partial<Record<iconElements, any>> = {}

const getCachedStyledIcon = (icon: iconElements) => {
  if (ICON_COMPONENT_CACHE[icon]) return ICON_COMPONENT_CACHE[icon]
  ICON_COMPONENT_CACHE[icon] = styled(RadixIcons[icon] || ADDITIONAL_ICONS[icon], StyledIconCss)
  return ICON_COMPONENT_CACHE[icon]
}

export const StyledIcon = styled('svg', StyledIconCss)

export type IconProps = {
  icon: iconElements
}

export const Icon = withStyledRef<typeof StyledIcon, IconProps>('Icon', ({ icon, children, ...props }, ref) => {
  if (icon) {
    const CachedIcon = getCachedStyledIcon(icon)
    return <CachedIcon ref={ref} {...props} />
  }

  return (
    <StyledIcon ref={ref} {...props}>
      {children}
    </StyledIcon>
  )
})
