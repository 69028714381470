import { useSetCanPermit, useSetInfiniteRiskyApproval, useUser } from '@/state/user/hooks'
import Box from '@/ui/Box'
import { Card } from '@/ui/Card'
import Text from '@/ui/Text'
import React from 'react'
import { BinarySwitch } from '../Inputs'
const ApprovalSettings: React.FC = () => {
  const { canPermit, infiniteRiskyApproval } = useUser()
  const setCanPermit = useSetCanPermit()
  const setInfiniteRiskyApproval = useSetInfiniteRiskyApproval()
  return (
    <Box>
      <Box css={{ pb: '$2' }}>
        <Text size="md" css={{ color: '$slate12' }}>
          Approvals
        </Text>
        <Text size="xs" color="loContrast" css={{ pb: '$3' }}>
          Approve token spending using gas-less permit signatures.
        </Text>
        <Card
          css={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            p: '$4',
            backgroundColor: '$slate4',
            alignItems: 'center',
          }}
        >
          <Text css={{ color: '$slate12', pr: '$2' }}>Approval</Text>
          <Box css={{ display: 'flex', alignItems: 'center' }}>
            <Text css={{ color: '$slate11', pr: '$2' }}>Use Permit</Text>
            <BinarySwitch
              disabled={true}
              value={canPermit}
              onChange={() => setCanPermit(!canPermit)}
              onLabel={'On'}
              offLabel={'Off'}
              aria-label="use permit"
            />
          </Box>
          <Box css={{ display: 'flex', alignItems: 'center' }}>
            <Text css={{ color: '$slate11', pr: '$2' }}>Infinite Approval</Text>
            <BinarySwitch
              value={infiniteRiskyApproval}
              onChange={() => setInfiniteRiskyApproval(!infiniteRiskyApproval)}
              onLabel={'On'}
              offLabel={'Off'}
              aria-label="use infinite approval"
            />
          </Box>
        </Card>
      </Box>
    </Box>
  )
}

export default ApprovalSettings
