import { useSetShowBalances, useUpdateSlippage, useUser } from '@/state/user/hooks'
import Box from '@/ui/Box'
import Text from '@/ui/Text'
import Input from '@/ui/Input'
import { validateSigmaInput } from '@/utils/validateSigmaInput'
import React, { useState } from 'react'
import { BinarySwitch } from '../Inputs'
import { Button } from '@/ui/Button'
import { Card } from '@/ui/Card'
import { useNavigate } from '@/hooks/useNavigate'

const PositionSettings: React.FC = () => {
  const { updateSimpleMode, getSimpleMode } = useNavigate()
  const simpleMode = getSimpleMode()
  const setShowBalances = useSetShowBalances()
  const slippagePresets = [0, 100, 250, 500, 1000]
  const updateSlippage = useUpdateSlippage()
  const { slippage, showBalances } = useUser()
  const [usingCustomSlippage, setUsingCustomSlippage] = useState(!slippagePresets.includes(parseInt(slippage)))
  const [customSlippage, setCustomSlippage] = useState(!slippagePresets.includes(parseInt(slippage)) ? slippage : '')
  const [slippageError, setSlippageError] = useState('')

  const useCustomSlippage = () => {
    setCustomSlippage(slippage)
    setUsingCustomSlippage(true)
  }
  const handleCustomSlippageChange = (event) => {
    if (event.target.value.length == 0 || validateSigmaInput(event.target.value)) {
      setCustomSlippage(event.target.value)
      if (parseInt(event.target.value) > 1e4) {
        setSlippageError('Slippage cannot exceed 100%')
        updateSlippage('10000')
        return
      }
      if (event.target.value) {
        updateSlippage(event.target.value)
        setSlippageError('')
      }
    }
  }
  const setSlippage = (slip: string) => {
    setUsingCustomSlippage(false)
    updateSlippage(slip)
  }

  const handleBlur = (event) => {
    if (event.target.value === '' && useCustomSlippage) {
      setCustomSlippage('100')
      updateSlippage('100')
    }
  }

  return (
    <Box className="flex flex-col">
      {/*
      <Text size="md" css={{ color: '$slate12' }}>
        Show position balances
      </Text>
      <Text size="xs" css={{ color: '$slate11', pb: '$3' }}>
        Show position balances in application.
      </Text>
      <Card
        css={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          backgroundColor: '$slate4',
          mb: '$3',
        }}
      >
        <Text>Primitive List</Text>
        <BinarySwitch value={showBalances} onChange={() => setShowBalances(!showBalances)} onLabel={'On'} offLabel={'Off'} />
      </Card>
    */}
      <Box css={{ mb: '$3' }}>
        <Text size="md" css={{ color: '$slate12' }}>
          Simple Mode
        </Text>
        <Text size="xs" color="loContrast" css={{ pb: '$3' }}>
          Use the Primitive platform with ease.
        </Text>
        <Box
          css={{
            p: '$4',
            flexDirection: 'row',
            backgroundColor: '$slate4',
            alignItems: 'center',
            borderRadius: '$md',
          }}
        >
          <Box
            css={{
              '@sm': { display: 'flex', justifyContent: 'space-between', mb: '$1', gap: 0 },
            }}
          >
            <Text size="md">Use Simple Liquidity</Text>
            <BinarySwitch onLabel="" offLabel="" value={simpleMode} onChange={() => updateSimpleMode(!simpleMode)} />
          </Box>
        </Box>
      </Box>
      <Box>
        <Text size="md" css={{ color: '$slate12' }}>
          Slippage Tolerance
        </Text>
        <Text size="xs" color="loContrast" css={{ pb: '$3' }}>
          % change in price allowed when adding/removing liquidity.
        </Text>
        <Box
          css={{
            p: '$4',
            flexDirection: 'row',
            backgroundColor: '$slate4',
            alignItems: 'center',
            borderRadius: '$md',
          }}
        >
          <Box
            css={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
              gap: '$2',
              mb: '$4',
              '@sm': { display: 'flex', justifyContent: 'space-between', mb: '$1', gap: 0 },
            }}
          >
            {slippagePresets.map((slippageAmount) => (
              <Button
                key={slippageAmount}
                color={!usingCustomSlippage && slippage.toString() === slippageAmount.toString() ? 'accent' : 'neutral'}
                aria-label={`slippage ${slippageAmount / 100}%`}
                css={{
                  padding: 0,
                  '@sm': {
                    p: '$2',
                  },
                }}
                onClick={() => setSlippage(slippageAmount.toString())}
              >
                {(slippageAmount / 100).toFixed(2)}%
              </Button>
            ))}
          </Box>
          <Box display="flex" align="center">
            <Button
              color={usingCustomSlippage ? 'accent' : 'neutral'}
              css={{ mr: '$2' }}
              onClick={() => useCustomSlippage()}
              aria-label={`slippage custom`}
            >
              Custom
            </Button>
            <Input
              disabled={!usingCustomSlippage}
              placeholder={'Basis Points'}
              value={customSlippage}
              onChange={handleCustomSlippageChange}
              css={{ textAlign: 'right' }}
              state={slippageError && usingCustomSlippage ? 'warning' : undefined}
              onBlur={handleBlur}
              title={slippageError ?? ''}
            />
            {usingCustomSlippage && customSlippage && <Text css={{ p: '$2' }}> = {parseInt(slippage) / 100}%</Text>}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default PositionSettings
