import React, { useState } from 'react'
import { Box } from '@/ui/Box'
import { Text } from '@/ui/Text'
import { Card } from '@/ui/Card'
import { AbstractConnector } from '@web3-react/abstract-connector'
import Image from 'next/image'

import Modal from '@/ui/Modal'
import { SUPPORTED_WALLETS } from '@/constants/wallets'
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { Button } from '@/ui/Button'

const Option = ({ onClick, header, icon }) => {
  return (
    <Box css={{ width: '100%' }}>
      <Card
        clickable={true}
        css={{
          width: '100%',
          marginBottom: '$4',
          transition: 'ease 0.2s all',
          backgroundColor: '$slate2',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
        onClick={onClick}
      >
        <Text css={{ fontSize: '$4', color: 'white' }}>{header}</Text>
        <Image src={icon} height={24} width={24} />
      </Card>
    </Box>
  )
}

const WalletModalButton: React.FC = () => {
  const { activate } = useWeb3React()
  const [_, setPendingWallet] = useState<AbstractConnector | undefined>()

  const tryActivation = async (connector: AbstractConnector | undefined) => {
    Object.keys(SUPPORTED_WALLETS).map((key) => {
      if (connector === SUPPORTED_WALLETS[key].connector) {
        return SUPPORTED_WALLETS[key].name
      }
      return true
    })
    setPendingWallet(connector) // set wallet for pending view

    // if the connector is walletconnect and the user has already tried to connect, manually reset the connector
    if (connector instanceof WalletConnectConnector) {
      connector.walletConnectProvider = undefined
    }

    connector &&
      activate(connector, undefined, true).catch((error) => {
        if (error instanceof UnsupportedChainIdError) {
          activate(connector) // a little janky...can't use setError because the connector isn't set
        }
      })
  }

  function getOptions() {
    return Object.keys(SUPPORTED_WALLETS).map((key) => {
      const option = SUPPORTED_WALLETS[key]
      return <Option onClick={() => tryActivation(option.connector)} key={key} header={option.name} icon={option.icon} />
    })
  }

  return (
    <Modal>
      <Modal.Trigger asChild>
        <Button variant="outline" tabIndex={0} css={{ px: '$2' }} aria-label="connect wallet">
          Connect to Wallet
        </Button>
      </Modal.Trigger>
      <Modal.Content css={{ width: '300px' }}>
        <Text size="xl" css={{ pb: '$2' }}>
          Select Wallet
        </Text>
        {getOptions()}
      </Modal.Content>
    </Modal>
  )
}

export default WalletModalButton
