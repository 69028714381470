import Big from 'big.js'

/**
 * Converts a value to a specific precision (or decimal points).
 *
 * Returns a string representing a number in fixed-point notation.
 *
 * @param value the value to convert
 * @param precision the precision or decimal points
 */
export function toFixed2(value: string | number = 0, precision: number) {
  try {
    return Big(value).toFixed(precision, Big.roundDown)
  } catch {
    return ''
  }
}
