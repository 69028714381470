import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useBlockNumber } from '@/hooks/data'
import { AppDispatch } from '../index'
import { updateTransaction } from './actions'
import { useWeb3React } from '@web3-react/core'
import { useTransactions } from './hooks'
import { SingleTx } from 'src/eth-tx-manager/singleTx'
import { NotificationTypes } from '../user/reducer'
import { useAddNotification } from '../user/hooks'

export default function Updater(): null {
  const state = useTransactions()
  const dispatch = useDispatch<AppDispatch>()
  const { chainId, library } = useWeb3React()
  const { data: lastBlockNumber } = useBlockNumber()
  const transactions = chainId ? state[chainId] ?? {} : {}
  const addNotification = useAddNotification()
  useEffect(() => {
    if (!chainId || !library || !lastBlockNumber) return
    const keys = Object.keys(transactions)
    for (const hash of keys) {
      const currentState = transactions[hash]
      if (!SingleTx.shouldSync(lastBlockNumber, currentState)) return
      // State update of `transactions[hash]` with a receipt, or lastCheckedBlockNumber
      currentState
        .poll(library, lastBlockNumber)
        .then((nextState) => {
          if (nextState) {
            dispatch(updateTransaction({ chainId: nextState.chainId, tx: nextState }))
            if (nextState.receipt) addNotification(NotificationTypes.SUCCESS, 'Tx success', nextState.hash)
          }
        })
        .catch((error) => {
          console.error(`failed to check transaction hash: ${hash}`, error)
          addNotification(NotificationTypes.ERROR, 'Failed to check transaction hash')
        })
    }
  }, [chainId, state, library, transactions, lastBlockNumber, dispatch])

  return null
}
