import { createReducer } from '@reduxjs/toolkit'
import { Pool } from '@primitivefi/rmm-sdk'
import {
  clearInput,
  typeInput,
  Field,
  typePriceInput,
  AddStatus,
  setStatus,
  PoolValidityStatus,
  setLiquidityError,
  clearLiquidityError,
  setInfotip,
  nextInfotip,
  registerInfotip,
  unRegisterInfotip,
  setFieldErrors,
} from './actions'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { isUndefined } from '@/utils/index'

dayjs.extend(utc)

export const ADD_LIQUIDITY_TEXT = {
  EXPIRY: 'Select Expiry',
  INVALID_EXPIRY: 'Provide a Valid Expiry',
  STRIKE: 'Select Strike Price',
  VOLATILITY: 'Select Implied Volatility',
  REFERENCE_PRICE: 'Provide a Reference Price',
  LIQUIDITY: 'Provide Deposit Amounts',
  INSUFFICIENT_BALANCE: 'Insufficient Token Balance',
  ADD: 'Add Liquidity',
}

export interface AddState {
  status: AddStatus
  constructedPool?: Pool
  openInfotip: number | undefined
  infotips: number[]
  readonly typedValue: string
  readonly independentField: Field
  readonly priceTypedValue: string
  poolValidityStatus: PoolValidityStatus
  liquidityErrors: {}
  fieldErrors: {
    wallet: string[]
    pools: string[]
    maturity: string[]
    strike: string[]
    sigma: string[]
    referencePrice: string[]
    balances: string[]
    risky: string[]
    stable: string[]
    liquidity: string[]
  } //Prepopulate keys for all fields with [] as default value
}

export const initialState: AddState = {
  status: AddStatus.UNLOADED,
  constructedPool: undefined,
  openInfotip: undefined,
  infotips: [],
  typedValue: '',
  independentField: Field.RISKY,
  priceTypedValue: '',
  poolValidityStatus: PoolValidityStatus.UNTOUCHED,
  liquidityErrors: {},
  fieldErrors: {
    wallet: [],
    pools: [],
    maturity: [],
    strike: [],
    sigma: [],
    referencePrice: [],
    balances: [],
    risky: [],
    stable: [],
    liquidity: [],
  },
}

export default createReducer<AddState>(initialState, (builder) =>
  builder
    .addCase(setInfotip, (state, { payload: openInfotip }) => {
      state.openInfotip = openInfotip
    })
    .addCase(registerInfotip, (state, { payload: infotip }) => {
      if (state.infotips.includes(infotip)) return state
      state.infotips = state.infotips.concat(infotip).sort()
    })
    .addCase(unRegisterInfotip, (state, { payload: infotip }) => {
      state.infotips = isUndefined(infotip) ? [] : state.infotips.filter((infoId) => infoId !== infotip).sort()
    })
    .addCase(nextInfotip, (state, { payload: n }) => {
      n = n ?? 1
      const currentIndex = state.openInfotip === undefined ? 0 : state.infotips.indexOf(state.openInfotip)
      const newIndex = Math.max(currentIndex, 0) + n
      // console.log('nextopen', n, state.openInfotip, currentIndex, newIndex, state.infotips[newIndex])

      if (newIndex < 0 || newIndex >= state.infotips.length) {
        state.openInfotip = undefined
        return state
      }

      state.openInfotip = state.infotips[newIndex]
    })
    .addCase(setStatus, (state, { payload: status }) => {
      return { ...state, status }
    })
    .addCase(clearInput, (state) => {
      const clearedKeys = [
        'status',
        'constructedPool',
        'typedValue',
        'independentField',
        'priceTypedValue',
        'poolValidityStatus',
      ]
      clearedKeys.forEach((k) => {
        state[k] = initialState[k]
      })
    })
    .addCase(typeInput, (state, { payload: { field, typedValue } }) => {
      return {
        ...state,
        typedValue,
        independentField: field,
      }
    })
    .addCase(typePriceInput, (state, { payload: { typedValue } }) => {
      return { ...state, priceTypedValue: typedValue }
    })
    .addCase(setLiquidityError, (state, { payload: { label, errorMessage } }) => {
      state.liquidityErrors[label] = errorMessage
    })
    .addCase(clearLiquidityError, (state, { payload: label }) => {
      const updatedState = { ...state.liquidityErrors }
      delete updatedState[label]
      state.liquidityErrors = updatedState
    })
    .addCase(setFieldErrors, (state, { payload: { field, errors } }) => {
      state.fieldErrors[field] = errors
    })
)
