import { styled } from '@/ui/theme'
// import type {RangeOf} from '@/utils/darkTypeMagic'

function generateSteps(property: string) {
  const result = {}

  new Array(9).fill('').forEach((_, i) => {
    result[i + 1] = {
      [property]: `$${i + 1}`,
    }
  })

  return result as {
    [key in 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9]: {
      [k: typeof property]: string
    }
  }
}

export const StyledBox = styled('div', {
  // Reset
  boxSizing: 'border-box',
  variants: {
    display: {
      flex: {
        display: 'flex',
      },
      grid: {
        display: 'grid',
      },
      'flex-col': {
        display: 'flex',
        flexDirection: 'column',
      },
      none: {
        display: 'none',
      },
    },
    align: {
      start: {
        alignItems: 'flex-start',
      },
      center: {
        alignItems: 'center',
      },
      end: {
        alignItems: 'flex-end',
      },
      stretch: {
        alignItems: 'stretch',
      },
      baseline: {
        alignItems: 'baseline',
      },
    },
    justify: {
      initial: {
        justifyContent: 'initial',
      },
      start: {
        justifyContent: 'flex-start',
      },
      center: {
        justifyContent: 'center',
      },
      end: {
        justifyContent: 'flex-end',
      },
      spaceBetween: {
        justifyContent: 'space-between',
      },
      'space-between': {
        justifyContent: 'space-between',
      },
      flexEnd: {
        justifyContent: 'flex-end',
      },
      'flex-end': {
        justifyContent: 'flex-end',
      },
    },
    flow: {
      row: {
        gridAutoFlow: 'row',
      },
      column: {
        gridAutoFlow: 'column',
      },
      dense: {
        gridAutoFlow: 'dense',
      },
      rowDense: {
        gridAutoFlow: 'row dense',
      },
      columnDense: {
        gridAutoFlow: 'column dense',
      },
    },
    columns: {
      1: {
        gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
      },
      2: {
        gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      },
      3: {
        gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
      },
      4: {
        gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
      },
    },
    rows: {
      1: {
        gridTemplateRows: 'repeat(1, minmax(0, 1fr))',
      },
      2: {
        gridTemplateRows: 'repeat(2, minmax(0, 1fr))',
      },
      3: {
        gridTemplateRows: 'repeat(3, minmax(0, 1fr))',
      },
      4: {
        gridTemplateRows: 'repeat(4, minmax(0, 1fr))',
      },
    },
    direction: {
      row: {
        flexDirection: 'row',
      },
      column: {
        flexDirection: 'column',
      },
      rowReverse: {
        flexDirection: 'row-reverse',
      },
      columnReverse: {
        flexDirection: 'column-reverse',
      },
      'row-reverse': {
        flexDirection: 'row-reverse',
      },
      'column-reverse': {
        flexDirection: 'column-reverse',
      },
    },
    wrap: {
      nowrap: {
        flexWrap: 'nowrap',
      },
      noWrap: {
        flexWrap: 'nowrap',
      },
      wrap: {
        flexWrap: 'wrap',
      },
      wrapReverse: {
        flexWrap: 'wrap-reverse',
      },
      'wrap-reverse': {
        flexWrap: 'wrap-reverse',
      },
    },
    gap: generateSteps('gap'),
    columnGap: generateSteps('columnGap'),
    rowGap: generateSteps('rowGap'),
    gapX: generateSteps('columnGap'),
    gapY: generateSteps('rowGap'),
  },
  defaultVariants: {},
})

// Just for export consistency
export const Box = StyledBox
