import Deployments from './abis/deployments.json'
import { constructSameAddressMap } from '@/utils/constructSameAddressMap'

export type AddressMap = { [key: number]: string }

export const FACTORIES: AddressMap = constructSameAddressMap(Deployments[4].rinkeby.contracts.PrimitiveFactory.address)
export const MANAGERS: AddressMap = constructSameAddressMap(Deployments[4].rinkeby.contracts.PrimitiveManager.address)
export const RENDERER: AddressMap = constructSameAddressMap(Deployments[4].rinkeby.contracts.PositionRenderer.address)

export const ENS_REGISTRAR_ADDRESSES: AddressMap = {
  1: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  4: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  42: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
}
