import React from 'react'
import * as Select from '@radix-ui/react-popover'
import Text from '@/ui/Text'
import Box from '@/ui/Box'
import Button from '@/ui/Button'
import Icon, { iconElements } from '@/ui/Icon'
import Card from '@/ui/Card'

type NavLink = {
  name: string
  icon: iconElements
  href: string
}

const LinksDropdown = () => {
  const navLinks: NavLink[] = [
    {
      name: 'About',
      icon: 'InfoCircledIcon',
      href: 'https://primitive.xyz',
    },
    {
      name: 'Docs',
      icon: 'ReaderIcon',
      href: 'https://library.primitive.xyz',
    },
    {
      name: 'Github',
      icon: 'GitHubLogoIcon',
      href: 'https://github.com/primitivefinance/',
    },
    {
      name: 'Blog',
      icon: 'Pencil1Icon',
      href: 'https://primitive.xyz/blog',
    },
    {
      name: 'Legal',
      icon: 'FileIcon',
      href: 'https://primitive.xyz/terms-of-service.pdf',
    },
  ]
  return (
    <Select.Root>
      <Select.Trigger asChild>
        <Button css={{ px: '$2' }} aria-label="app select">
          <Box
            css={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
            color="hiContrast"
          >
            <Icon icon="DotsVerticalIcon"></Icon>
          </Box>
        </Button>
      </Select.Trigger>

      <Select.Content>
        <Card
          css={{
            mt: '$1',
            backgroundColor: '$neutral3',
            px: '$2',
            py: '$2',
            borderRadius: '$md',
            display: 'flex',
            flexDirection: 'column',
            width: 140,
          }}
        >
          {navLinks.map((link, index) => (
            <a href={link.href} key={index} target="_blank" rel="noreferrer">
              <Box
                css={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  p: '$1',
                  color: '$neutral11',
                  '&:hover': {
                    color: '$neutral12',
                  },
                }}
              >
                <Text size="sm">{link.name}</Text>
                <Icon icon={link.icon}></Icon>
              </Box>
            </a>
          ))}
        </Card>
      </Select.Content>
    </Select.Root>
  )
}

export default LinksDropdown
