import Deployments from '../abis/deployments.json'

export const WBTC_LOGO_URI = 'https://tokens.1inch.exchange/0x2260fac5e5542a773aa44fbcfedf7c193bc2c599.png'
export const RBN_LOGO_URI = 'https://assets.coingecko.com/coins/images/15823/small/RBN_64x64.png?1633529723'
export const YFI_LOGO_URI = 'https://assets.coingecko.com/coins/images/11849/thumb/yfi-192x192.png?1598325330'
export const YVECRV_LOGO_URI = 'https://raw.githubusercontent.com/curvefi/curve-assets/main/images/logo.png'

export const WETH_LOGO_URI = '/tokens/eth-icon.png'
export const WETH_BACKGROUND_COLOR = '#627EEA'
// note: wbtc on real network has 8 decimals
export const WBTC_METADATA = {
  name: 'WBTCoin',
  symbol: 'WBTC',
  decimals: 6,
  logoURI: WBTC_LOGO_URI,
}

export const YVECRV_METADATA = {
  name: 'Test yveCRV-DAO yVault',
  symbol: 'yveCRV',
  decimals: 18,
  logoURI: YVECRV_LOGO_URI,
}

export const YFI_METADATA = {
  name: 'Yfi Test',
  symbol: 'YFI',
  decimals: 18,
  logoURI: YFI_LOGO_URI,
}

export const RBN_METADATA = {
  name: 'Ribbon Test',
  symbol: 'RBN',
  decimals: 18,
  logoURI: RBN_LOGO_URI,
}

export const WETH_METADATA = {
  name: 'Wrapped Ether',
  symbol: 'WETH',
  decimals: 18,
  logoURI: WETH_LOGO_URI,
  backgroundColor: WETH_BACKGROUND_COLOR,
}

export const MAINNET_DEFAULT_RISKY_TOKENS = [
  { address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2', chainId: 1, ...WETH_METADATA },
]

export const ROPSTEN_DEFAULT_RISKY_TOKENS = !Deployments[3]
  ? []
  : [
      {
        address: Deployments[3].ropsten.contracts.WBTC.address,
        chainId: 3,
        ...WBTC_METADATA,
      },
    ]

export const RINKEBY_DEFAULT_RISKY_TOKENS = !Deployments[4]
  ? []
  : [
      {
        address: '0xc778417e063141139fce010982780140aa0cd5ab',
        chainId: 4,
        ...WETH_METADATA,
      },
      {
        address: '0x54c31B268802dc5E4f10F4df98fc0B79Be5A4dCe',
        chainId: 4,
        ...YVECRV_METADATA,
      },
      {
        address: '0xcE5497AC20f3C505e74ECF63FD22Bf7e3914Cb4A',
        chainId: 4,
        ...RBN_METADATA,
      },
    ]

export const GOERLI_DEFAULT_RISKY_TOKENS = !Deployments[5]
  ? []
  : [
      {
        address: Deployments[5].goerli.contracts.WBTC.address,
        chainId: 5,
        ...WBTC_METADATA,
      },
    ]

export const KOVAN_DEFAULT_RISKY_TOKENS = !Deployments[42]
  ? []
  : [
      {
        address: Deployments[42].kovan.contracts.WBTC.address,
        chainId: 42,
        ...WBTC_METADATA,
      },
      {
        address: Deployments[42].kovan.contracts.YFI.address,
        chainId: 42,
        ...YFI_METADATA,
      },
      {
        address: Deployments[42].kovan.contracts.RBN.address,
        chainId: 42,
        ...RBN_METADATA,
      },
    ]

export const ARBITRUM_ONE_DEFAULT_RISKY_TOKENS = !Deployments[42161]
  ? []
  : [
      {
        address: Deployments[42161].arbtriumone.contracts.WBTC.address,
        chainId: 42161,
        ...WBTC_METADATA,
      },
    ]

export const ARBITRUM_RINKEBY_DEFAULT_RISKY_TOKENS = !Deployments[421611]
  ? []
  : [
      {
        address: Deployments[421611].arbitrumrinkeby.contracts.WBTC.address,
        chainId: 421611,
        ...WBTC_METADATA,
      },
    ]

export const OPTIMISM_DEFAULT_RISKY_TOKENS = !Deployments[10]
  ? []
  : [
      {
        address: Deployments[10].optimism.contracts.WBTC.address,
        chainId: 10,
        ...WBTC_METADATA,
      },
    ]

export const OPTIMISTIC_KOVAN_DEFAULT_RISKY_TOKENS = !Deployments[69]
  ? []
  : [
      {
        address: Deployments[69].optimistickovan.contracts.WBTC.address,
        chainId: 69,
        ...WBTC_METADATA,
      },
    ]

export const DEFAULT_RISKY_TOKENS = {
  1: MAINNET_DEFAULT_RISKY_TOKENS,
  3: ROPSTEN_DEFAULT_RISKY_TOKENS,
  4: RINKEBY_DEFAULT_RISKY_TOKENS,
  5: GOERLI_DEFAULT_RISKY_TOKENS,
  10: OPTIMISM_DEFAULT_RISKY_TOKENS,
  42: KOVAN_DEFAULT_RISKY_TOKENS,
  69: OPTIMISTIC_KOVAN_DEFAULT_RISKY_TOKENS,
  42161: ARBITRUM_ONE_DEFAULT_RISKY_TOKENS,
  421611: ARBITRUM_RINKEBY_DEFAULT_RISKY_TOKENS,
}
