import React from 'react'
import { keyframes } from '@stitches/react'
import { styled, CSS } from '@/ui/theme'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import { Cross2Icon } from '@radix-ui/react-icons'
import { Button } from '@/ui/Button'
import { withStyledRef } from '@/ui/theme/utils'


const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
})

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
})

const StyledOverlay = styled(DialogPrimitive.Overlay, {
  position: 'fixed',
  backgroundColor: 'rgba(0, 0, 0, .40)',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },
  zIndex: 3,
})

type BaseModalProps = React.ComponentProps<typeof DialogPrimitive.Root>
export default function Modal({ children, ...props }: BaseModalProps) {
  return (
    <DialogPrimitive.Root {...props}>
      <StyledOverlay />
      {children}
    </DialogPrimitive.Root>
  )
}

export const StyledContent = styled(DialogPrimitive.Content, {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 3,
  minWidth: 200,
  maxHeight: '85vh',
  padding: '$4',
  marginTop: '-5vh',
  borderRadius: '14px',
  backgroundColor: '$slate3',
  willChange: 'transform',
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },
  '&:focus': {
    outline: 'none',
  },
})

const StyledCloseButton = styled(DialogPrimitive.Close, {
  position: 'absolute',
  top: '$2',
  right: '$2',
})

type ModalContentPrimitiveProps = React.ComponentProps<typeof DialogPrimitive.Content>
type ModalContentProps = ModalContentPrimitiveProps & { css?: CSS }

export const ModalContent = withStyledRef<typeof StyledContent, ModalContentProps>(
  'ModalContent',
  ({ children, ...props }, ref) => {
    return (
      <StyledContent {...props} ref={ref}>
        {children}
        <StyledCloseButton asChild>
          <Button variant="ghost">
            <Cross2Icon />
          </Button>
        </StyledCloseButton>
      </StyledContent>
    )
  }
)

export const ModalTrigger = DialogPrimitive.Trigger
export const ModalClose = DialogPrimitive.Close
export const ModalTitle = DialogPrimitive.Title
export const ModalDescription = DialogPrimitive.Description
