import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useActiveWeb3React } from '@/hooks/web3'

import { Operation } from '@/constants/index'
import { AppDispatch, AppState } from '../index'
import { addTransaction, clearAllTransactions } from './actions'
import { TransactionState } from './reducer'
import { SerializableTransactionReceipt, TransactionKind } from 'src/eth-tx-manager/types'
import { SingleTx, TxStatus } from 'src/eth-tx-manager/singleTx'

export const useTransactions = (): TransactionState => {
  const state = useSelector<AppState, AppState['transactions']>((state) => state.transactions)
  return state
}

export const useTransactionAdder = (): ((tx: TransactionKind, orderType: Operation) => void) => {
  const { chainId, account } = useActiveWeb3React()
  const dispatch = useDispatch<AppDispatch>()

  return useCallback(
    (tx, orderType) => {
      if (!account) return
      if (!chainId) return

      const { hash } = tx
      if (!hash) throw Error('No transaction hash found.')
      dispatch(
        addTransaction({
          tx,
          orderType,
        })
      )
    },
    [dispatch, chainId, account]
  )
}

export const useClearTransactions = (): (() => void) => {
  const { chainId } = useActiveWeb3React()
  const dispatch = useDispatch<AppDispatch>()

  return useCallback(() => {
    if (!chainId) return
    dispatch(
      clearAllTransactions({
        chainId,
      })
    )
  }, [dispatch, chainId])
}

export const useAllTransactions = (): (() => {
  [txHash: string]: TransactionKind
}) => {
  const { chainId } = useActiveWeb3React()
  const state = useTransactions()

  return useCallback(() => {
    return chainId ? state[chainId] : {}
  }, [chainId, state])
}

export const useIsTransactionPending = (): ((transactionHash?: string) => boolean) => {
  const transactions = useAllTransactions()

  return useCallback(
    (transactionHash?: string) => {
      const allTx = transactions()
      if (!transactionHash || !allTx[transactionHash]) return false

      return !allTx[transactionHash].receipt
    },
    [transactions, useAllTransactions]
  )
}

export const useTransactionReceipt = (): ((transactionHash?: string) => SerializableTransactionReceipt | undefined) => {
  const transactions = useAllTransactions()

  return useCallback(
    (transactionHash?: string) => {
      const allTx = transactions()
      if (!allTx) return undefined
      if (!transactionHash) return undefined

      const tx = allTx[transactionHash]
      if (!tx) return undefined

      if (!tx.receipt) return undefined
      else return tx.receipt
    },
    [transactions, useAllTransactions]
  )
}

export const useTransactionKind = (): ((transactionHash?: string) => TransactionKind | undefined) => {
  const transactions = useAllTransactions()

  return useCallback(
    (transactionHash?: string) => {
      const allTx = transactions()
      if (!allTx) return undefined
      if (!transactionHash) return undefined

      const tx = allTx[transactionHash]
      if (!tx) return undefined
      else return tx
    },
    [transactions, useAllTransactions]
  )
}

export function useHasPendingApproval(tokenAddress: string | undefined, spender: string | undefined): boolean {
  const transactions = useAllTransactions()
  return useMemo(() => {
    const allTxs = transactions()
    return (
      typeof tokenAddress === 'string' &&
      typeof spender === 'string' &&
      allTxs &&
      Object.keys(allTxs).some((hash) => {
        const tx = allTxs[hash]
        if (!tx) return false
        if (tx.receipt) {
          return false
        } else {
          return (
            tx?.metadata?.spender === spender && tx?.metadata?.token === tokenAddress && SingleTx.isTransactionRecent(tx)
          )
        }
      })
    )
  }, [transactions, spender, tokenAddress])
}

export function useApprovalTransactionStatus(
  tokenAddress?: string | undefined,
  spender?: string | undefined
): { hash: string; status: TxStatus } {
  const transactions = useAllTransactions()
  return useMemo(() => {
    const allTxs = transactions()
    let status = { hash: '', status: TxStatus.PREPARED }

    if (typeof tokenAddress === 'string' && typeof spender === 'string' && allTxs) {
      Object.keys(allTxs).forEach((hash) => {
        const tx: SingleTx = allTxs[hash]

        if (tx?.receipt) {
          status = { hash: hash, status: tx.status }
        } else if (
          tx?.metadata?.spender === spender &&
          tx?.metadata?.token === tokenAddress &&
          SingleTx.isTransactionRecent(tx)
        ) {
          status = { hash: hash, status: tx.status }
        }
      })
    }

    return status
  }, [transactions, spender, tokenAddress])
}
