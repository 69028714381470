import { withStatic } from '@/ui/theme/utils'
import { Card, CardBody, CardFooter, CardHeader, CardInner } from './Card'
export * from './Card'

export default withStatic(Card, {
  Footer: CardFooter,
  Body: CardBody,
  Header: CardHeader,
  Inner: CardInner,
})
