import { createReducer } from '@reduxjs/toolkit'
import {
  updateOnboarding,
  updateRiskyTokenLists,
  updateStrikeToken,
  updateSlippage,
  addNotification,
  clearNotifications,
  selectPreferredChain,
  setShowBalances,
  setCanPermit,
  setInfiniteRiskyApproval,
  setLastUpdatedChainLocation,
  setAcceptedTOS,
} from './actions'
import { DEFAULT_SLIPPAGE_TOLERANCE } from '@/constants/index'
import { DEFAULT_RISKY_TOKENS } from '@/constants/tokenLists/risky'
import { WETH } from '@uniswap/sdk'
import { Token as BaseToken } from '@uniswap/sdk-core'

export enum OnboardingState {
  Connected = 'CONNECTED',
  Whitelisted = 'WHITELISTED',
  Agreed = 'AGREED',
}

export type TokenList = {
  name: string
  tokens: Token[]
  timestamp?: string
  keywords?: string[]
  logoURI?: string
  status: boolean
  decimals?: number
}

export interface Token extends BaseToken {
  logoURI?: string
}

export interface UserState {
  onboarding: OnboardingState
  acceptedTOS: boolean
  strikeToken: Token | undefined
  riskyTokenLists: { [listName: string]: TokenList }
  slippage: string
  notifications: Notification[]
  preferredChain: number
  showBalances: boolean
  canPermit: boolean
  infiniteRiskyApproval: boolean
  lastUpdatedChainLocation: string
}

export enum NotificationTypes {
  PENDING = 'Pending',
  SUCCESS = 'Successful',
  FAILURE = 'Failed',
  REJECTION = 'Rejected',
  ERROR = 'Error',
}
export interface Notification {
  type: NotificationTypes
  message: string
  hash?: string
  timestamp: number
}

export const getInitialState = (): UserState => {
  if (typeof window !== 'undefined') {
    return {
      onboarding: OnboardingState.Connected,
      acceptedTOS: JSON.parse(localStorage.getItem(`@primitive/tos`) ?? 'false'),
      strikeToken: JSON.parse(localStorage.getItem('strikeToken') ?? '{}'),
      riskyTokenLists: JSON.parse(localStorage.getItem('riskyTokenLists') ?? '{}'),
      slippage: JSON.parse(localStorage.getItem('slippage') ?? DEFAULT_SLIPPAGE_TOLERANCE.toString()),
      notifications: JSON.parse(localStorage.getItem('notifications') ?? '[]'),
      preferredChain: parseInt(localStorage.getItem('preferredChain') ?? '0'),
      showBalances: true,
      canPermit: false,
      infiniteRiskyApproval: JSON.parse(localStorage.getItem('infiniteRiskyApproval') ?? 'false'),
      lastUpdatedChainLocation: 'route',
    }
  }
  return {
    onboarding: OnboardingState.Connected,
    acceptedTOS: false,
    strikeToken: undefined,
    riskyTokenLists: {
      ['DEFAULT']: {
        name: 'default risky tokens',
        status: true,
        tokens: Object.keys(DEFAULT_RISKY_TOKENS).map((key) => DEFAULT_RISKY_TOKENS[key]) ?? WETH,
      },
    },
    slippage: DEFAULT_SLIPPAGE_TOLERANCE.toString(),
    notifications: [],
    preferredChain: 0,
    showBalances: true,
    canPermit: false,
    infiniteRiskyApproval: false,
    lastUpdatedChainLocation: 'route',
  }
}

export default createReducer<UserState>(getInitialState(), (builder) =>
  builder
    .addCase(updateOnboarding, (state, { payload: onboarding }) => {
      return { ...state }
    })
    .addCase(updateStrikeToken, (state, { payload: strikeToken }) => {
      return { ...state, strikeToken }
    })
    .addCase(updateRiskyTokenLists, (state, { payload: riskyTokenLists }) => {
      return { ...state, riskyTokenLists }
    })
    .addCase(updateSlippage, (state, { payload: slippage }) => {
      return { ...state, slippage }
    })
    .addCase(clearNotifications, (state) => {
      return { ...state, notifications: [] }
    })
    .addCase(addNotification, (state, { payload: notification }) => {
      return { ...state, notifications: state.notifications.concat(notification) }
    })
    .addCase(selectPreferredChain, (state, { payload: chainId }) => {
      return { ...state, preferredChain: chainId }
    })
    .addCase(setShowBalances, (state, { payload: showBalances }) => {
      return { ...state, showBalances }
    })
    .addCase(setCanPermit, (state, { payload: canPermit }) => {
      return { ...state, canPermit }
    })
    .addCase(setInfiniteRiskyApproval, (state, { payload: infiniteRiskyApproval }) => {
      return { ...state, infiniteRiskyApproval }
    })
    .addCase(setLastUpdatedChainLocation, (state, { payload: lastUpdatedChainLocation }) => {
      return { ...state, lastUpdatedChainLocation: lastUpdatedChainLocation }
    })
    .addCase(setAcceptedTOS, (state, { payload: acceptedTOS }) => {
      return { ...state, acceptedTOS: acceptedTOS }
    })
)
