import React from 'react'
import { Text, TextCSS, TextProps } from '@/ui/Text'
import { VariantProps, CSS, styled } from '@/ui/theme'
import { withStyledRef } from '@/ui/theme/utils'

const DEFAULT_TAG = 'h2'

// TODO - figure out how to extend default variables.
export const StyledHeading = styled(DEFAULT_TAG, TextCSS, {
  fontVariantNumeric: 'proportional-nums',
  m: 0,
  display: 'flex',
  fontWeight: 'semibold',
})

type HeadingProps = {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | React.ComponentType | React.ElementType
}

export const Heading = withStyledRef<typeof StyledHeading, HeadingProps>('Heading', ({ as, size, ...props }, ref) => {
  const sizeMap = { h1: '3xl', h2: '2xl', h3: 'xl', h4: 'lg', h5: 'md', h6: 'md' }
  const semanticSizing = (typeof as === 'string' && sizeMap[as]) || undefined

  return <StyledHeading variant="secondary" ref={ref} as={as} size={size || semanticSizing} {...props} />
})
