import { isValidSigma } from '@primitivefi/rmm-sdk'
export const validateSigmaInput = (sigmaInput = '') => {
  const sanitized = sigmaInput.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
  const inputSigmaRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`)
  const valid =
    sanitized.length > 0 &&
    inputSigmaRegex.test(sanitized) &&
    sanitized[sanitized.length - 1] !== '.' &&
    isValidSigma(sigmaInput)
  return valid
}
