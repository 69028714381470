import { Web3Provider } from '@ethersproject/providers'
import { useWeb3React } from '@web3-react/core'
import { useEffect, useState, useCallback } from 'react'

import { injected, network } from '@/connectors/index'
import { NetworkContextName, DEFAULT_CHAIN_ID } from '@/constants/index'
//import { isMobile } from '@/utils/userAgent'

export function useEagerConnect() {
  const { activate, active, library } = useWeb3React()
  const [tried, setTried] = useState(false)

  useEffect(() => {
    if (!active) {
      injected.isAuthorized().then((isAuthorized) => {
        if (isAuthorized) {
          activate(injected, undefined, true).catch(() => {
            setTried(true)
          })
        } else {
          if (!window.ethereum) {
            setTried(true)
            return
          }
          activate(network, undefined, true).catch(() => {
            setTried(true)
          })
        }
      })
    }
  }, [activate, active])

  // wait until we get confirmation of a connection to flip the flag
  useEffect(() => {
    if (active) {
      setTried(true)
    }
  }, [active])

  return tried
}

export function useActiveWeb3React() {
  const clientNetwork = useWeb3React<Web3Provider>()
  const defaultNetwork = useWeb3React<Web3Provider>(NetworkContextName)
  return clientNetwork.active
    ? { ...clientNetwork, account: clientNetwork.account }
    : { ...defaultNetwork, account: false || defaultNetwork.account }
}

// this function is listening for chainId changes when client network is inactive. Stops listening for chainId changes when and removes listener when activated
export function useInactiveListener(suppress = false) {
  const { active, error, activate } = useWeb3React()

  useEffect(() => {
    const { ethereum } = window

    if (ethereum && ethereum.on && !active && !error && !suppress) {
      const handleChainChanged = () => {
        // eat errors
        activate(injected, undefined, true).catch((error) => {
          console.error('Failed to activate after chain changed', error)
        })
      }

      const handleAccountsChanged = (accounts: string[]) => {
        if (accounts.length > 0) {
          // eat errors
          activate(injected, undefined, true).catch((error) => {
            console.error('Failed to activate after accounts changed', error)
          })
        }
      }

      ethereum.on('chainChanged', handleChainChanged)
      ethereum.on('accountsChanged', handleAccountsChanged)

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('chainChanged', handleChainChanged)
          ethereum.removeListener('accountsChanged', handleAccountsChanged)
        }
      }
    }
    return undefined
  }, [active, error, suppress, activate])
}
