import { styled, CSS, css, theme, createInternalColors } from '@/ui/theme'

const radixPopoverTrigger = `&[data-radix-popover-trigger][data-state="open"], &[data-radix-dropdown-menu-trigger][data-state="open"]`

export const ButtonStyles = css({
  ...createInternalColors('neutral'),
  // Custom
  position: 'relative',
  borderRadius: '$md',
  margin: '0',
  display: 'inline-flex',
  flexShrink: 0,
  alignItems: 'center',
  justifyContent: 'center',
  verticalAlign: 'middle',
  lineHeight: '$tight',
  transitionProperty: 'background-color,border-color,color,fill,stroke,box-shadow',
  transitionDuration: '75ms',
  transitionTimingFunction: 'cubic-bezier(0,0,.2,1)',
  cursor: 'pointer',
  usa: 'none',
  whiteSpace: 'nowrap',
  borderWidth: 1,
  borderStyle: 'solid',
  columnGap: '$2',

  px: '$sizes$4',

  '&:focus-visible': {
    outline: '1px solid',
  },

  '&:disabled': {
    backgroundColor: '$slate2',
    color: '$slate8',
    pointerEvents: 'none',
  },

  variants: {
    size: {
      xs: {
        fontSize: '$xs',
        height: 'auto',
        columnGap: '$2',
        px: 2,
        py: 2,
        borderRadius: '$sm',
      },
      sm: {
        fontSize: '$sm',
        height: '$sizes$8',
        columnGap: '$2',
        minWidth: '$sizes$8',
      },
      md: {
        fontSize: '$sm',
        height: '$sizes$9',
        columnGap: '$3',
        minWidth: '$sizes$9',
      },
      lg: {
        fontSize: '$base',
        lineHeight: '$normal',
        height: '$sizes$11',
        columnGap: '$4',
        minWidth: '$sizes$11',
      },
    },
    rounded: {
      true: {
        borderRadius: '$round',
      },
    },
    icon: {
      true: {},
    },
    color: {
      primary: createInternalColors('primary'),
      accent: createInternalColors('accent'),
      success: createInternalColors('success'),
      warning: createInternalColors('warning'),
      danger: createInternalColors('danger'),
    },
    wide: {
      true: {
        width: '100%',
      },
    },
    variant: {
      link: {
        borderColor: 'transparent',
        backgroundColor: 'transparent',
        color: '$$color9',
        '@hover': {
          '&:hover': {
            color: '$$color10',
            textDecoration: 'underline',
          },
        },
        '&:active': {
          textDecoration: 'underline',
        },
        '&:focus': {
          textDecoration: 'underline',
        },
        '&:disabled': {
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          boxShadow: 'none',
        },
        [radixPopoverTrigger]: {
          textDecoration: 'underline',
        },
      },
      solid: {
        borderColor: 'transparent',
        backgroundColor: '$$color9',
        color: '$$color12',
        '@hover': {
          '&:hover': {
            backgroundColor: '$$color10',
          },
        },
        '&:active': {
          color: '$$color11',
        },
        '&:focus': {
          borderColor: '$$color10',
        },
        '&:disabled': {
          backgroundColor: '$$color1',
        },
        [radixPopoverTrigger]: {
          borderColor: '$$color10',
          color: '$$color11',
        },
      },
      outline: {
        borderColor: '$$color7',
        backgroundColor: 'transparent',
        color: '$$color12',
        '@hover': {
          '&:hover': {
            borderColor: '$$color9',
          },
        },
        '&:active': {
          color: '$$color11',
        },
        '&:focus': {
          borderColor: '$$color10',
        },
        [radixPopoverTrigger]: {
          borderColor: '$$color10',
          color: '$$color11',
        },
      },
      light: {
        borderColor: 'transparent',
        backgroundColor: '$$color4',
        color: '$$color12',
        '@hover': {
          '&:hover': {
            backgroundColor: '$$color5',
          },
        },
        '&:active': {
          backgroundColor: '$$color6',
        },
        '&:focus': {
          borderColor: '$$color7',
        },
        [radixPopoverTrigger]: {
          borderColor: '$$color7',
          backgroundColor: '$$color6',
        },
      },
      ghost: {
        borderColor: 'transparent',
        backgroundColor: 'transparent',
        color: '$$color12',
        '@hover': {
          '&:hover': {
            backgroundColor: '$$color4',
          },
        },
        '&:active': {
          color: '$$color11',
          backgroundColor: '$$color5',
        },
        '&:focus': {
          backgroundColor: '$$color5',
        },
        [radixPopoverTrigger]: {
          backgroundColor: '$$color5',
          color: '$$color11',
        },
      },
    },
  },
  compoundVariants: [
    // TODO - style appropriately
    {
      icon: true,
      size: 'sm',
      css: {
        px: '$sizes$2',
      },
    },
    {
      icon: true,
      size: 'md',
      css: {
        px: '$sizes$2',
      },
    },
    {
      icon: true,
      size: 'lg',
      css: {
        px: '$sizes$2',
      },
    },
  ],
  defaultVariants: {
    size: 'md',
    variant: 'light',
  },
})
