import { ALL_SUPPORTED_PRODUCTION_CHAINS, ALL_SUPPORTED_TESTNET_CHAINS } from '@/constants/chains'

export const getChainNameFromId = (
  chainId = 1,
  prodChains = ALL_SUPPORTED_PRODUCTION_CHAINS,
  testChains = ALL_SUPPORTED_TESTNET_CHAINS
) => {
  const a = [...prodChains, ...testChains].find((chain) => chain.chainId === chainId)
  return a
}
