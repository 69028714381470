import React from 'react'
import { css, styled } from '@/ui/theme/stitches.config'
import { withStyledRef } from '@/ui/theme/utils'
import { Box } from '../Box'
import Icon from '../Icon'

const styleCardBase = css({
  // $$borderColor: '$neutral6',
})

const StyledCardFooter = styled('div', {
  flexGrow: 0,
  borderTopStyle: 'solid',
  borderTopWidth: '1px',
  borderColor: '$$borderColor',
})

type CardFooterProps = {}

export const CardFooter = withStyledRef<typeof StyledCardFooter, CardFooterProps>(
  'CardFooter',
  ({ children, ...props }, ref) => {
    return (
      <StyledCardFooter ref={ref} {...props}>
        {children}
      </StyledCardFooter>
    )
  }
)

const StyledCardBody = styled('div', {
  flex: 1,
  m: '-$2',
  bg: '$neutral7',
  p: '$2',
  borderTop: 'solid 1px',
  borderBottom: 'solid 1px',
  [`& + ${StyledCardFooter}`]: {
    borderTopWidth: 0,
  },
})

type CardBodyProps = {}

export const CardBody = withStyledRef<typeof StyledCardBody, CardBodyProps>('CardBody', ({ children, ...props }, ref) => {
  return (
    <StyledCardBody ref={ref} {...props}>
      {children}
    </StyledCardBody>
  )
})

const StyledCardInner = styled('div', {
  bg: 'transparent',
  width: '100%',
  p: '$1',
  borderRadius: '6px', // TODO - :(
  border: 'solid 1px',
})

type CardInnerProps = {}

export const CardInner = withStyledRef<typeof StyledCardInner, CardInnerProps>(
  'CardInner',
  ({ children, className = '', ...props }, ref) => {
    return (
      <StyledCardInner ref={ref} className={`card-inner ${className}`} {...props}>
        {children}
      </StyledCardInner>
    )
  }
)

const StyledCardHeader = styled('div', styleCardBase, {
  flexGrow: 1,
  flexShrink: 0,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative',
})

type CardHeaderProps = {
  withCaret: boolean
}

export const CardHeader = withStyledRef<typeof StyledCardHeader, CardHeaderProps>(
  'CardHeader',
  ({ withCaret, children, ...props }, ref) => {
    if (withCaret)
      return (
        <StyledCardHeader ref={ref} {...props}>
          <Box display="flex" justify="space-between" align="center">
            {children}
          </Box>
          <Icon icon="CaretRightIcon" size="2xl" />
        </StyledCardHeader>
      )

    return (
      <StyledCardHeader ref={ref} {...props}>
        {children}
      </StyledCardHeader>
    )
  }
)

const StyledCard = styled('div', {
  $$borderColor: '$colors$neutral3',
  $$internalBorderColor: '$colors$neutral7',
  $$xp: '$sizes$6',
  background: '$neutral3',
  border: '1px solid $$borderColor',
  borderRadius: '$base',
  py: '$$xp',
  px: '$$xp',

  display: 'flex',
  flexDirection: 'column',

  [`& > ${StyledCardHeader}`]: {
    mx: '-$$xp',
    px: '$$xp',
    pb: '$2',
    borderColor: '$$internalBorderColor',
  },

  [`& ${StyledCardInner}`]: {
    borderColor: '$$internalBorderColor',
  },

  [`& > ${StyledCardBody}`]: {
    mx: '-$$xp',
    p: '$$xp',
    borderColor: '$$internalBorderColor',
    background: '$neutral2',
  },

  [`& > ${StyledCardFooter}`]: {
    mx: '-$$xp',
    px: '$$xp',
    pt: '$$xp',
    borderColor: '$$internalBorderColor',
  },

  variants: {
    clickable: {
      true: {
        cursor: 'pointer',
        '&:hover': {
          borderColor: '$neutral10',
        },
      },
    },
  },
})

type CardProps = {}

export const Card = withStyledRef<typeof StyledCard, CardProps>(
  'Card',
  ({ onClick, children, clickable, ...props }, ref) => {
    const isClickable = !!onClick || clickable

    return (
      <StyledCard
        tabIndex={isClickable ? -1 : undefined}
        onClick={!onClick ? undefined : onClick}
        ref={ref}
        clickable={isClickable}
        {...props}
      >
        {children}
      </StyledCard>
    )
  }
)
