import { parsePercentage, Percentage } from 'web3-units'

export const ACTIVE_NETWORK = Number(process.env.NEXT_PUBLIC_ACTIVE_NETWORK)
export const DEFAULT_CHAIN_ID = ACTIVE_NETWORK
export const NetworkContextName = 'NETWORK'

export const DEFAULT_GAMMA = parsePercentage(0.99)
export const DEFAULT_SLIPPAGE_TOLERANCE: Percentage = parsePercentage(0.0)
export const DEFAULT_MAX_SIGMA = 1e7

export enum QueryParameters {
  INPUT = 'input',
  OUTPUT = 'output',
  CHAIN = 'chain',
}

export const WETH_ADDRESS: { [key: number]: string } = {
  1: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
  4: '0xc778417E063141139Fce010982780140Aa0cD5Ab',
  42: '0xD309E394c8f43263324835b6cbFEdCB231Ad87AE',
}
export const USDC_ADDRESS: { [key: number]: string } = {
  1: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
  4: '0x5358F3D94fffb9c360699e18eeA8E12ecC723ED9',
  42: '0x91273A317fAe3B411eF8985B8907E5386D40CC0d',
}

export const startingBlock = {
  421611: 12890000,
}

export enum Operation {
  ADD = 'Add Liquidity',
  REMOVE = 'Remove Liquidity',
  APPROVE = 'Approval',
}

export enum TransactionFailCodes {
  REJECTED = 4001,
  INVALID_PARAMS = -32602,
  INTERNAL_ERROR = -32603,
}

export const ERC20_BYTES32 = [
  {
    constant: true,
    inputs: [],
    name: 'name',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    payable: false,
    stateMutability: 'pure',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'symbol',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    payable: false,
    stateMutability: 'pure',
    type: 'function',
  },
]
