import React, { useEffect, useMemo } from 'react'
import Modal from '@/ui/Modal'
import { useAddNotification, useselectPreferredChain, useUser } from '@/state/user/hooks'
import { ALL_SUPPORTED_CHAIN_IDS, ALL_SUPPORTED_PRODUCTION_CHAINS, ALL_SUPPORTED_TESTNET_CHAINS } from '@/constants/chains'
import { useActiveWeb3React } from '@/hooks/web3'
import classNames from 'classnames'
import { getChainDataById } from '@/utils/getChainData'
import { useHideChainSelectModal } from '@/state/modal/hooks'
import { Web3Provider } from '@ethersproject/providers'
import { NotificationTypes } from '@/state/user/reducer'
import { getChainNameFromId } from '@/utils/getChainNameFromId'
import { Button } from '@/ui/Button'
import Text from '@/ui/Text'
import Box from '@/ui/Box'
import { useNavigate } from '@/hooks/useNavigate'
import Icon from '@/ui/Icon'
import { Card } from '@/ui/Card'
import { useDispatch } from 'react-redux'
import { setLastUpdatedChainLocation } from '@/state/user/actions'

export const PreferredChainModalButton: React.FC = () => {
  const selectChainId = useselectPreferredChain()
  const { preferredChain } = useUser()
  const { chainId, library } = useActiveWeb3React()
  const currentChain = getChainDataById([...ALL_SUPPORTED_PRODUCTION_CHAINS, ...ALL_SUPPORTED_TESTNET_CHAINS], chainId)
  const chainName = useMemo(() => {
    return getChainNameFromId(chainId)
  }, [chainId])
  const navigate = useNavigate()
  const hideChainSelectModal = useHideChainSelectModal()
  const addNotification = useAddNotification()
  const dispatch = useDispatch()

  const handleChainSelect = (selectedChainId) => {
    selectChainId(selectedChainId)
    hideChainSelectModal()
    const asInteger = parseInt(selectedChainId)
    dispatch(setLastUpdatedChainLocation('app'))
    library &&
      library
        .send('wallet_switchEthereumChain', [{ chainId: '0x' + asInteger.toString() }])
        .then(() => {
          navigate.updateChainIdInRoute(selectedChainId)
        })
        .catch((switchError: any) => {
          addNotification(NotificationTypes.ERROR, 'Error switching networks')
        })
  }

  useEffect(() => {
    if (currentChain === preferredChain) {
      hideChainSelectModal()
    }
  }, [chainId])

  const NetworkCard = ({ chain, interactive }) => {
    return (
      <Modal.Close>
        <Card
          data-cy={`${chain.name}-chain-card`}
          css={{
            width: '100%',
            backgroundColor: chain.chainId === chainId ? '$teal4' : '$neutral4',
            p: '$2',
            rounded: '$md',
            fontSize: '$sm',
          }}
          onClick={() => (interactive ? handleChainSelect(chain.chainId) : null)}
        >
          {chain.name}
        </Card>
      </Modal.Close>
    )
  }

  return (
    <Modal>
      <Modal.Trigger asChild>
        <Button variant="outline" css={{ px: '$2' }} data-cy="network-select-button" aria-label="select network">
          <img height={20} width={20} src="/tokens/eth-icon.png" alt="chain" />
          <Text inline size="sm" color="hiContrast" css={{ display: 'flex', alignItems: 'center' }}>
            {chainName?.name}
          </Text>
        </Button>
      </Modal.Trigger>
      <Modal.Content css={{ width: '100%', maxWidth: 450 }}>
        {currentChain ? (
          <Box>
            <Text size="lg" css={{ mb: '$4' }}>
              Select a Network
            </Text>
            <Text size="sm" css={{ mb: '$2' }}>
              Mainnet
            </Text>
            <div className="grid grid-cols-3 gap-3 mb-4">
              {ALL_SUPPORTED_PRODUCTION_CHAINS.map((chain) => {
                return <NetworkCard key={chain.chainId} chain={chain} interactive={true} />
              })}
            </div>
            <Text size="sm" css={{ mb: '$2' }}>
              Testnet
            </Text>
            <div className="grid grid-cols-3 gap-3">
              {ALL_SUPPORTED_TESTNET_CHAINS.map((chain) => {
                return <NetworkCard key={chain.chainId} chain={chain} interactive={true} />
              })}
            </div>
          </Box>
        ) : (
          <Box>
            <Text inline css={{ mb: '$4' }}>
              Current chain is unsupported. Please connect via a different chain or disconnect your wallet to view the site
              in read-only mode.
            </Text>

            <Text className="text-lg text-white mb-2">Supported Chains</Text>
            <Text className="text-white mb-2 text-sm">Production</Text>
            <div className="grid grid-cols-3 gap-3 mb-4">
              {ALL_SUPPORTED_PRODUCTION_CHAINS.map((chain) => {
                return <NetworkCard key={chain.chainId} chain={chain} interactive={true} />
              })}
            </div>
            <Text className="text-white mb-2 text-sm">Testnet</Text>
            <div className="grid grid-cols-3 gap-3">
              {ALL_SUPPORTED_TESTNET_CHAINS.map((chain) => {
                return <NetworkCard key={chain.chainId} chain={chain} interactive={true} />
              })}
            </div>
          </Box>
        )}
      </Modal.Content>
    </Modal>
  )
}
