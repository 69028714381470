import * as LabelPrimitive from '@radix-ui/react-label'
import { styled } from '@/ui/theme'
import { Text } from '@/ui/Text'
import { withStyledRef } from '@/ui/theme/utils';

export const StyledLabel = styled(LabelPrimitive.Root, Text, {
  display: 'inline-block',
  verticalAlign: 'middle',
  cursor: 'default',
});


export type LabelProps = {}

export const Label = withStyledRef<typeof StyledLabel, LabelProps>('InputLabel', (props, ref) => {
  return (
    <StyledLabel ref={ref}  {...props} /> 
  )
})

export default Label
