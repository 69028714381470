import React from 'react'
import { styled, CSS, css, mapTheme } from '@/ui/theme'
import { withStyledRef } from '@/ui/theme/utils'

export const TextCSS = css({
  color: 'inherit',
  variants: {
    inline: {
      true: {
        display: 'inline',
      },
    },
    size: mapTheme<'fontSizes'>('fontSizes', (fontSize) => ({
      fontSize,
    })),
    variant: {
      primary: {
        fontWeight: 400,
      },
      secondary: {
        fontWeight: 600,
        WebkitFontSmoothing: 'antialiased',
      },
      tertiary: {
        fontWeight: 500,
        WebkitFontSmoothing: 'antialiased',
      },
    },
    color: {
      danger: {
        color: '$danger11',
      },
      accent: {
        color: '$accent11',
      },
      primary: {
        color: '$primary11',
      },
      success: {
        color: '$success11',
      },
      warning: {
        color: '$warning11',
      },
      hiContrast: {
        color: '$hiContrast',
      },
      loContrast: {
        color: '$loContrast',
      },
    },
    gradient: {
      true: {
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
      },
    },
  },
  compoundVariants: [
    {
      color: 'primary',
      gradient: 'true',
      css: {
        background: 'linear-gradient(to right, $primary11, $accent11)',
      },
    },
  ],
})

export const StyledText = styled('p', TextCSS)

export type TextProps = {
  inline?: boolean
}

export const Text = withStyledRef<typeof StyledText, TextProps>('Text', ({ inline, ...props }, ref) => {
  return <StyledText variant="primary" ref={ref} as={inline ? 'span' : 'p'} inline={inline} {...props} />
})

export default Text
