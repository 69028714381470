import React, { useState } from 'react'
interface HamburgerMenuIconProps {
  open: boolean
  handleClick: () => void
}
const HamburgerMenuIcon: React.FC<HamburgerMenuIconProps> = ({ open, handleClick }) => {
  const [internalOpenState, setInternalOpenState] = useState(open)
  const toggle = () => {
    setInternalOpenState(!internalOpenState)
    handleClick()
  }
  return (
    <button id="nav-icon4" className={internalOpenState ? 'open' : ''} onClick={() => toggle()}>
      <span></span>
      <span></span>
      <span></span>
      <style jsx>
        {`
          * {
            margin: 0;
            padding: 0;
          }

          /* Icon 1 */

          #nav-icon4 {
            width: 30px;
            height: 20px;
            position: relative;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: 0.5s ease-in-out;
            -moz-transition: 0.5s ease-in-out;
            -o-transition: 0.5s ease-in-out;
            transition: 0.5s ease-in-out;
            cursor: pointer;
          }

          #nav-icon4 span {
            display: block;
            position: absolute;
            height: 3px;
            width: 100%;
            background: #f4f4f4;
            border-radius: 9px;
            opacity: 1;
            left: 0;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: 0.25s ease-in-out;
            -moz-transition: 0.25s ease-in-out;
            -o-transition: 0.25s ease-in-out;
            transition: 0.25s ease-in-out;
          }

          /* Icon 4 */

          #nav-icon4 {
          }

          #nav-icon4 span:nth-child(1) {
            top: 0px;
            -webkit-transform-origin: left center;
            -moz-transform-origin: left center;
            -o-transform-origin: left center;
            transform-origin: left center;
          }

          #nav-icon4 span:nth-child(2) {
            top: 9px;
            -webkit-transform-origin: left center;
            -moz-transform-origin: left center;
            -o-transform-origin: left center;
            transform-origin: left center;
          }

          #nav-icon4 span:nth-child(3) {
            top: 18px;
            -webkit-transform-origin: left center;
            -moz-transform-origin: left center;
            -o-transform-origin: left center;
            transform-origin: left center;
          }

          #nav-icon4.open span:nth-child(1) {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
            top: -2.8px;
            left: 0px;
          }

          #nav-icon4.open span:nth-child(2) {
            width: 0%;
            opacity: 0;
          }

          #nav-icon4.open span:nth-child(3) {
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
            top: 18px;
            left: 0px;
          }
        `}
      </style>
    </button>
  )
}

export default HamburgerMenuIcon
