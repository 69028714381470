import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import transactions from './transactions/reducer'
import add from './add/reducer'
import strike from './strike/reducer'
import manage from './manage/reducer'
import user from './user/reducer'
import modal from './modal/reducer'

const store = configureStore({
  reducer: {
    user,
    transactions,
    add,
    strike,
    manage,
    modal,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
  }),
})

export default store

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
