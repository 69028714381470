import React from 'react'
import classNames from 'classnames'
import Box from '@/ui/Box'
import Icon from '@/ui/Icon'
import M from '@/singletons/MonitoringSingleton'

interface BinarySwitchProps {
  onLabel: string
  offLabel: string
  value: boolean
  onChange: (status: boolean) => void
  disabled?: boolean
}

export const BinaryToggle: React.FC<BinarySwitchProps> = ({ onLabel, offLabel, value, onChange, disabled, ...props }) => (
  <>
    <Box
      css={{
        rounded: '$lg',
        height: 20,
        width: 36,
        backgroundColor: value ? '$indigo9' : '$slate9',
        display: 'flex',
        alignItems: 'center',
        justifyContent: value ? 'flex-end' : 'flex-start',
        px: '2px',
        cursor: disabled ? 'not-allowed' : 'pointer',
        opacity: disabled ? '50%' : '100%',
      }}
      className={classNames(props['aria-label'] ? 'ph-no-capture' : '')}
      onClick={() => {
        disabled
          ? null
          : props['aria-label']
          ? M.captureFn(onChange, `toggled ${!value ? 'on' : 'off'}: ${props['aria-label']}`, {
              toggleState: !value,
            })(value)
          : onChange(value)
      }}
    >
      <Box css={{ height: 16, width: 16, backgroundColor: '$slate12', rounded: '$xl' }}></Box>
    </Box>
  </>
)

export const BinaryCheckbox: React.FC<BinarySwitchProps> = ({ onLabel, offLabel, value, onChange, disabled }) => (
  <>
    <Box
      css={{
        rounded: '$sm',
        height: 16,
        width: 16,
        backgroundColor: value ? '$indigo9' : '$slate9',
        cursor: disabled ? 'not-allowed' : 'pointer',
        backgroundImage: '.',
        opacity: disabled ? '50%' : '100%',
      }}
      onClick={() => (disabled ? null : onChange(!value))}
    >
      {value && <Icon icon="CheckIcon" />}
    </Box>
  </>
)

export default BinaryToggle
