import React from 'react'
import { styled, CSS, VariantProps } from '@/ui/theme'
import { ButtonStyles } from './Button.styles'
import Spinner from '@/ui/Spinner'
import { mapChildren, withStyledRef, withId } from '@/ui/theme/utils'
import cx from 'classnames'
import M from '@/singletons/MonitoringSingleton'

export const StyledButton = styled('button', ButtonStyles)

export type ButtonProps = {
  loading?: boolean
  loadingText?: string
}

export const Button = withStyledRef<typeof StyledButton, ButtonProps>(
  'Button',
  ({ children, loading, loadingText, className, asChild, ...props }, ref) => {
    return (
      <StyledButton
        className={cx('Button', props['aria-label'] ? 'ph-no-capture' : '', className)}
        {...props}
        onClick={M.captureFn(props.onClick, M.getEventName(props, 'clicked:'), {
          loading: props.loading,
          disabled: props.disabled,
          button_label: props.value,
        })}
        ref={ref}
      >
        {loading && <Spinner height={8} width={8} />}
        {loading ? loadingText : children}
      </StyledButton>
    )
  }
)

export const StyledButtonGroup = styled('div', {
  [`& ${Button}`]: {
    borderRadius: 0,
    borderRightWidth: 0,
    '&:hover': {
      borderRightWidth: 1,
    },
    [`&:hover + ${Button}`]: {
      borderLeftWidth: 0,
    },
    '&:focus': {
      borderRightWidth: 1,
    },
    [`&:focus + ${Button}`]: {
      borderLeftWidth: 0,
    },

    '&:active': {
      borderRightWidth: 1,
    },
    [`&:active + ${Button}`]: {
      borderLeftWidth: 0,
    },

    '&:first-child': {
      borderTopLeftRadius: '$sizes$2',
      borderBottomLeftRadius: '$sizes$2',
    },
    '&:last-child': {
      borderTopRightRadius: '$sizes$2',
      borderBottomRightRadius: '$sizes$2',
      borderRightWidth: 1,
    },
  },
})

export type ButtonGroupProps = ButtonProps & VariantProps<typeof StyledButton> & {}

export const ButtonGroup = withStyledRef<typeof StyledButtonGroup, ButtonGroupProps>(
  'ButtonGroup',
  ({ children, className, size, variant, color, disabled, loading, ...props }, ref) => {
    return (
      <StyledButtonGroup className={cx('ButtonGroup', className)} {...props} ref={ref}>
        {mapChildren(children, (child, { childType, first, last, ...h }) => {
          if (childType.includes('Button')) {
            return React.cloneElement(child, {
              size: child.props.size || size,
              color: child.props.color || color,
              variant: child.props.variant || variant,
              disabled: child.props.disabled || disabled,
              loading: child.props.loading || loading,
            })
          }
          return child
        })}
      </StyledButtonGroup>
    )
  }
)
