import React, { useState } from 'react'
import { BinarySwitch } from '@/components/Inputs'
import { useAddNotification, useAddRiskyTokenList, useToggleRiskyTokenListStatus, useUser } from '@/state/user/hooks'
import { NotificationTypes, TokenList } from '@/state/user/reducer'
import Text from '@/ui/Text'
import Box from '@/ui/Box'
import { Button } from '@/ui/Button'
import { Input } from '@/ui/Input'
import { Card } from '@/ui/Card'

const TokenListSettings: React.FC = () => {
  const [tokenListUrl, setTokenListUrl] = useState('')
  const [tokenImportError, setTokenImportError] = useState(false)
  const { riskyTokenLists } = useUser()
  const initialPendingTokenListState = {
    name: '',
    tokens: [] as any[],
    tokenCount: 0,
    logoURI: '',
  }
  const [pendingTokenList, setPendingTokenList] = useState(initialPendingTokenListState)
  const addRiskyTokenList = useAddRiskyTokenList()
  const toggleRiskyTokenListStatus = useToggleRiskyTokenListStatus()
  const addNotification = useAddNotification()

  const handleTokenListUrlChange = (event) => {
    setTokenImportError(false)
    setTokenListUrl(event.target.value)
  }
  const addTokenList = async (tokenListUrl: string) => {
    const response = await fetch(tokenListUrl)
    if (response.ok) {
      setTokenImportError(false)
      const data: TokenList = await response.json()
      setPendingTokenList({
        name: data.name,
        tokens: data.tokens,
        tokenCount: data.tokens.length,
        logoURI: data.logoURI ?? '',
      })
    } else {
      setTokenImportError(true)
      addNotification(NotificationTypes.ERROR, 'Unable to import token list')
    }
  }

  const importTokenList = () => {
    addRiskyTokenList({ ...pendingTokenList, status: true })
    setPendingTokenList(initialPendingTokenListState)
    setTokenListUrl('')
  }

  const toggleTokenListState = (tokenListName) => {
    toggleRiskyTokenListStatus(tokenListName)
  }

  return (
    <Box>
      <Text size="md" css={{ color: '$slate12' }}>
        Token Lists
      </Text>
      <Text size="xs" css={{ color: '$slate11', pb: '$3' }}>
        Manage the token lists you would like to use within the Primitive application
      </Text>
      <Box className="flex justify-between">
        <Input
          placeholder="https://"
          css={{ width: '100%', mr: '$2' }}
          value={tokenListUrl}
          onChange={handleTokenListUrlChange}
          disabled={true}
        />
        <Button css={{ padding: '$5', boxShadow: '$l3' }} onClick={() => addTokenList(tokenListUrl)} disabled={true}>
          +
        </Button>
      </Box>

      <Box className="pb-2 text-red text-xs">{tokenImportError ? 'Error Importing Token List' : ''}</Box>
      {pendingTokenList.name && (
        <Box>
          <Card
            css={{
              padding: '$2',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: '$slate4',
              mb: '$2',
            }}
          >
            <Box css={{ display: 'flex' }}>
              <img alt="icon" height={48} width={48} src={pendingTokenList.logoURI} />
              <Box css={{ pl: '$4' }}>
                <Text color={'hiContrast'}>{pendingTokenList.name}</Text>
                <Text color={'loContrast'}>{pendingTokenList.tokenCount} Tokens</Text>
              </Box>
            </Box>
            <Button onClick={() => importTokenList()}>Import</Button>
          </Card>
        </Box>
      )}

      {Object.keys(riskyTokenLists).length > 0 && (
        <div className="overflow-scroll">
          {Object.keys(riskyTokenLists).map((tokenListName) => (
            <Box
              css={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                backgroundColor: '$slate4',
                padding: '$3',
                borderRadius: '$md',
              }}
              key={riskyTokenLists[tokenListName].name}
            >
              <Text className="flex items-center">
                <img
                  alt="token-list"
                  height={24}
                  width={24}
                  src={
                    riskyTokenLists[tokenListName].logoURI && !riskyTokenLists[tokenListName].logoURI?.includes('ipfs://')
                      ? riskyTokenLists[tokenListName].logoURI
                      : '/missing-icon.svg'
                  }
                />
                <Text css={{ pl: '$2' }}>{riskyTokenLists[tokenListName].name}</Text>
              </Text>
              <BinarySwitch
                value={riskyTokenLists[tokenListName].status}
                onChange={() => toggleTokenListState(riskyTokenLists[tokenListName].name)}
                onLabel={'On'}
                offLabel={'Off'}
              />
            </Box>
          ))}
        </div>
      )}
    </Box>
  )
}

export default TokenListSettings
