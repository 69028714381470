import { getAddSlug, getManageSlug } from '@/utils/slugs'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { DEFAULT_CHAIN_ID } from '@/constants/index'
import { isAddress } from '../utils'
import { ALL_SUPPORTED_CHAIN_IDS } from '@/constants/chains'
import { QUOTE_TOKENS } from '@/constants/tokenLists/quote'
import { DEFAULT_RISKY_TOKENS } from '@/constants/tokenLists/risky'
import { isNil } from '@/utils/index'
import M from '@/singletons/MonitoringSingleton'
// TODO: Add route parsing to navigate object

type QueryModes = {
  editPool?: boolean
}

type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] }
type AllRequired<T> = {
  [P in keyof T]-?: T[P]
}
type AddLiquidityParams = {
  chainId?: number | string
  risky: string
  stable: string
  expiry?: string
  strike?: string
  sigma?: string
  gamma?: string
} & QueryModes

export const useNavigate = (addRouteFactory = getAddSlug, manageRouteFactory = getManageSlug) => {
  const router = useRouter()

  const { params: __, ...query } = router.query

  const getSimpleMode = () => isNil(router.query.editPool) || router.query.editPool === 'false'
  const queryStringFactory = (editPool) => {
    if (typeof editPool === 'boolean') {
      const q: QueryModes = {
        ...query,
        editPool,
      }

      if (!editPool) {
        delete q['editPool']
      }

      return q
    }
    return query
  }

  return {
    toHome: () => router.push('/'),
    toURL: (url) => router.push(url),
    toAddLiquidity: ({ chainId, risky, stable, expiry, strike, sigma, gamma, editPool }: AddLiquidityParams) => {
      M.captureEvent('navigate: to add', { chainId, risky, stable, expiry, strike, sigma, gamma })
      router.push(
        {
          pathname: addRouteFactory({ chainId, asset: 'ETH', quote: stable, maturity: expiry, strike, sigma, gamma }),
          query: queryStringFactory(editPool),
        },
        undefined,
        { shallow: true }
      )
    },
    toManageLiquidity: ({ chainId, risky, stable, account, poolId }) => {
      M.captureEvent('navigate: to manage', { chainId, risky, stable, account, poolId })
      router.push(
        {
          pathname: manageRouteFactory({ chainId, asset: risky, quote: stable, account, poolId }),
        },
        undefined,
        { shallow: true }
      )
    },
    getSimpleMode,
    getAddLiquidityRouteParams: () => {
      return useMemo(() => {
        const routerParams: AddLiquidityParams & { chainId: string; risky: string; stable: string } = {
          chainId: '',
          risky: '',
          stable: '',
          expiry: undefined,
          strike: undefined,
          sigma: undefined,
          gamma: undefined,
        }
        // TODO: Find a better way to do this
        Object.keys(routerParams).forEach((key, index) => {
          routerParams[key] = Array.isArray(router.query.params) ? router?.query?.params[index] : undefined
        })
        routerParams.risky = 'ETH'
        routerParams.editPool = !getSimpleMode()
        return routerParams
      }, [router.query])
    },
    getManageLiquidityRouteParams: () => {
      return useMemo(() => {
        const routerParams = {
          chainId: '',
          owner: '',
          risky: '',
          stable: '',
          poolId: undefined,
        }
        // TODO: Find a better way to do this
        Object.keys(routerParams).forEach((key, index) => {
          routerParams[key] = Array.isArray(router.query.params) ? router?.query?.params[index] : undefined
        })
        return routerParams
      }, [router.query])
    },
    getChainIdFromRoute: (currentId: number | undefined, fallbackId = DEFAULT_CHAIN_ID) => {
      if (
        Array.isArray(router.query.params) &&
        router.query.params.length > 0 &&
        ALL_SUPPORTED_CHAIN_IDS.includes(parseInt(router.query.params[0]))
      ) {
        return parseInt(router.query.params[0])
      }
      return currentId ?? fallbackId
    },
    updateChainIdInRoute: (targetChainId) => {
      if (Array.isArray(router.query.params) && router.query.params.length > 0) {
        const basePath = router.asPath.replace(router.query.params.join('/'), '')
        const updatedParams = router.query.params
        updatedParams[0] = targetChainId
        if (isAddress(updatedParams[1])) {
          updatedParams[1] = DEFAULT_RISKY_TOKENS[targetChainId][0].address
        }
        if (isAddress(updatedParams[2])) {
          updatedParams[2] = QUOTE_TOKENS[targetChainId][0].address
        }
        router.push(`${basePath}${updatedParams.join('/')}`, undefined, { shallow: true })
      }
    },
    updateSimpleMode: (simpleMode) => {
      router.push(
        {
          pathname: router.pathname,
          query: {
            ...(router.query.params ? { params: router.query.params } : {}),
            ...queryStringFactory(!simpleMode),
          },
        },
        undefined,
        { shallow: true }
      )
    },
  }
}
