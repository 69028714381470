import { withStatic } from '../theme/utils'
import Modal, { ModalClose, ModalContent, ModalDescription, ModalTitle, ModalTrigger } from './Modal'
export * from './Modal'

export default withStatic(Modal, {
  Title: ModalTitle,
  Trigger: ModalTrigger,
  Content: ModalContent,
  Close: ModalClose,
  Description: ModalDescription,
})
