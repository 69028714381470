import { createAction } from '@reduxjs/toolkit'

export enum Field {
  RISKY = 'RISKY',
  STABLE = 'STABLE',
  RMM_LP = 'RMM_LP',
}

export enum PoolValidityStatus {
  INVALID = 'INVALID',
  VALID = 'VALID',
  UNTOUCHED = 'UNTOUCHED',
}

export enum AddStatus {
  UNLOADED = 'UNLOADED',
  IMPORTED = 'IMPORTED',
  DEFAULT_POOL = 'DEFAULT_POOL',
  LOADING_POOL = 'LOADING_POOL',
  CUSTOM_POOL = 'CUSTOM_POOL',
  POOL_SELECTED = 'POOL_SELECTED',
  INPUT_AMOUNTS = 'INPUT_AMOUNTS',
  INCOMPLETE = 'INCOMPLETE',
  APPROVED = 'APPROVED',
  STALE = 'STALE',
  READY = 'READY',
  APPROVING = 'APPROVING',
  REJECTED = 'REJECTED',
  FAILED = 'FAILED',
  CONFIRMING = 'CONFIRMING',
  CONFIRMED = 'CONFIRMED',
  MINED = 'MINED',
}

export const setStatus = createAction<AddStatus>('add/setStatus')
export const registerInfotip = createAction<number>('add/registerInfotip')
export const unRegisterInfotip = createAction<number | undefined>('add/unRegisterInfotip')
export const setInfotip = createAction<number | undefined>('add/openInfotip')
export const nextInfotip = createAction<number | undefined>('add/openNextInfotip')
export const clearInput = createAction<void>('add/clearInput')
export const typeInput = createAction<{ field: Field; typedValue: string }>('add/typeInput')
export const typePriceInput = createAction<{ typedValue: string }>('add/typePriceInput')
export const setLiquidityError = createAction<{ label: string; errorMessage: string }>('add/setLiquidityError')
export const clearLiquidityError = createAction<string>('add/clearLiquidityError')
export const setFieldErrors = createAction<{ field: string; errors: string[] }>('add/setFieldErrors')
