import { isAddress } from '../utils'
import { useWeb3React } from '@web3-react/core'
import { useEffect, useState } from 'react'

export default function useENSName(address: string | null | undefined) {
  const { library } = useWeb3React()

  const [ENSName, setENSName] = useState<string | null | undefined>()

  useEffect(() => {
    if (address && isAddress(address) && library) {
      let stale = false
      library
        .lookupAddress(address)
        .then((name: string) => {
          if (!stale) {
            if (name) {
              setENSName(name)
            } else {
              setENSName(undefined)
            }
          }
        })
        .catch(() => {
          if (!stale) {
            setENSName(undefined)
          }
        })

      return () => {
        stale = true
        setENSName(undefined)
      }
    }
  }, [library, address])

  return ENSName
}
