import { createReducer } from '@reduxjs/toolkit'
import { BigNumberish } from 'ethers'
import { typeRemoveInput, updateHistoricalPosition } from './actions'

export interface ManageState {
  historical: {
    totalLiquidity: BigNumberish | null
    totalRisky: BigNumberish | null
    totalStable: BigNumberish | null
  }
  readonly removeTypedInput: string
}

export const initialState: ManageState = {
  historical: {
    totalLiquidity: null,
    totalRisky: null,
    totalStable: null,
  },
  removeTypedInput: '',
}

export default createReducer<ManageState>(initialState, (builder) =>
  builder
    .addCase(updateHistoricalPosition, (state, { payload: { totalLiquidity, totalRisky, totalStable } }) => {
      return {
        ...state,
        historical: {
          ...state.historical,
          totalLiquidity,
          totalRisky,
          totalStable,
        },
      }
    })
    .addCase(typeRemoveInput, (state, { payload: { typedValue } }) => {
      return {
        ...state,
        removeTypedInput: typedValue,
      }
    })
)
