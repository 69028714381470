import { useWeb3React } from '@web3-react/core'
import { useEffect } from 'react'
import { NetworkContextName } from '../constants'

import { network } from '../connectors'
import { useEagerConnect, useInactiveListener } from '../hooks/web3'

export default function Web3ReactManager({ children }: { children: JSX.Element }) {
  const { active: clientNetworkActive } = useWeb3React()
  const defaultWeb3Network = useWeb3React(NetworkContextName)
  const { active: defaultNetworkActive, error: defaultNetworkError, activate: activateDefaultNetwork } = defaultWeb3Network

  // try to eagerly connect to an injected provider, if it exists and has granted access already
  const triedEager = useEagerConnect()

  // after eagerly trying injected, if the network connect ever isn't active or in an error state, activate itd
  useEffect(() => {
    if (triedEager && !defaultNetworkActive && !defaultNetworkError && !clientNetworkActive) {
      activateDefaultNetwork(network)
    }
  }, [triedEager, defaultNetworkActive, activateDefaultNetwork, clientNetworkActive, defaultNetworkError])

  // when there's no account connected, react to logins (broadly speaking) on the injected provider, if it exists
  useInactiveListener(!triedEager)

  return children
}
