import { AbstractConnector } from '@web3-react/abstract-connector'

import { injected, walletconnect } from '../connectors'

interface WalletInfo {
  connector?: AbstractConnector
  name: string
  icon: string
  description: string
  color: string
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    icon: '/metamask-fox.svg',
    description: 'Easy-to-use browser extension.',
    color: '#E8831D',
  },
  WALLET_CONNECT: {
    connector: walletconnect,
    name: 'WalletConnect',
    icon: '/walletconnect-logo.svg',
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    color: '#4196FC',
  },
}
