import { getAddress } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import { Contract } from '@ethersproject/contracts'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import type { Provider } from '@ethersproject/abstract-provider'
import type { ContractInterface } from '@ethersproject/contracts'
// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: string): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

// account is not optional
function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

export function getContract(address: string, ABI: ContractInterface, library: Web3Provider, account?: string): Contract {
  if ((!isAddress(address) || address === AddressZero) && address !== 'ETH') {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(
    address,
    ABI,
    getProviderOrSigner(library, account) as JsonRpcSigner | Provider | any // TODO replace with correct Signer type
  )
}

//uniswap call utils
export interface Call {
  address: string
  callData: string
  gasRequired?: number
}

export function toCallKey(call: Call): string {
  let key = `${call.address}-${call.callData}`
  if (call.gasRequired) {
    if (!Number.isSafeInteger(call.gasRequired)) {
      throw new Error(`Invalid number: ${call.gasRequired}`)
    }
    key += `-${call.gasRequired}`
  }
  return key
}

export function parseCallKey(callKey: string): Call {
  const pcs = callKey.split('-')
  if (![2, 3].includes(pcs.length)) {
    throw new Error(`Invalid call key: ${callKey}`)
  }
  return {
    address: pcs[0],
    callData: pcs[1],
    ...(pcs[2] ? { gasRequired: Number.parseInt(pcs[2]) } : {}),
  }
}

/**
 * @notice Splits `arr` into `n` chunks
 * @param arr Array to chunk
 * @param n Number of chunks desired
 * @returns An array of chunks with length `n`
 */
export function chunkArray(arr, n) {
  const chunkLength = Math.max(arr.length / n, 1)
  const chunks: any[] = []
  for (let i = 0; i < n; i++) {
    if (chunkLength * (i + 1) <= arr.length) chunks.push(arr.slice(chunkLength * i, chunkLength * (i + 1)))
  }
  return chunks
}

/**
 * @param uri JSON string with a `base64` encoding
 * @returns Parsed JSON
 */
export function parseTokenURI(uri: string) {
  const json = Buffer.from(uri.substring(29), 'base64').toString() //(uri.substring(29));
  const result = JSON.parse(json)
  return result
}

export function isNil<T>(value: T | undefined | null): value is undefined | null {
  return <T>value === undefined || <T>value === null
}

export function isUndefined<T>(value: T | undefined): value is undefined {
  return typeof (<T>value) === 'undefined'
}
