import React, { useState } from 'react'
import { useBlockNumber } from '@/hooks/data'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import useInterval from '@/hooks/utils/useInterval'
import Box from '@/ui/Box'
import Text from '@/ui/Text'
import Icon from '@/ui/Icon'
import Link from 'next/link'

dayjs.extend(utc)
dayjs.extend(timezone)

export const Footer: React.FC = () => {
  const { data } = useBlockNumber()
  const [utcDateString, setUtcDateString] = useState(dayjs().utc().format('MMM D, YYYY hh:mm:ss'))

  useInterval(() => setUtcDateString(dayjs().utc().format('MMM D, YYYY h:mm:ss')), 1000)

  const footerLinkStyle = {
    color: '$slate9',
    '&:hover': {
      color: '$slate11',
    },
  }
  return (
    <>
      <Box
        css={{
          position: 'fixed',
          display: 'flex',
          alignItems: 'center',
          padding: '$2',
          left: 0,
          bottom: 0,
        }}
      >
        <Box display="flex-col">
          <Box display="flex" css={{ color: '$slate8' }}>
            <Text inline size="xs" css={{ pr: '$1' }}>
              Made by
            </Text>
            <Text size="xs" inline css={footerLinkStyle}>
              <a href="https://primitive.xyz/">Primitive</a>
            </Text>
            <Text size="xs" css={{ color: 'rgba(0,129,241, 1)' }}>
              .
            </Text>
          </Box>
          <Box display="flex">
            <Text size="xs" inline css={{ pr: '$1', color: '$slate8' }}>
              {' '}
              Interface
            </Text>
            <Text size="xs" css={footerLinkStyle}>
              v1.0.0-alpha.1

            </Text>
          </Box>
          <Box display="flex">
            <Text size="xs" inline css={{ pr: '$1', color: '$slate8' }}>
              {' '}
              Core
            </Text>
            <Text size="xs" css={footerLinkStyle}>
              <a href="https://github.com/primitivefinance/rmm-core/releases/tag/v2.0.0" target="_blank" rel="noreferrer">
                v2.0.0
              </a>
            </Text>
          </Box>
        </Box>
      </Box>

      <Box
        css={{
          position: 'fixed',
          right: 0,
          bottom: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          p: '$2',
        }}
      >
        <Box css={{ display: 'flex', alignItems: 'center' }}>
          <Box css={{ mr: '$1' }}>
            <img src="/block-icon.svg" alt="block icon" />
          </Box>
          <Text color="success" size="xs">
            {data ? data : 'loading'}
          </Text>
        </Box>
        {
          <Text inline css={{ ml: '$1' }} size="xs" color="success">
            {' '}
            {utcDateString}
            {' UTC'}
          </Text>
        }
      </Box>
    </>
  )
}
