import { useCallback, useMemo } from 'react'
import { useLocalStorage } from 'react-use'

import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState } from '../index'
import {
  updateOnboarding,
  updateRiskyTokenLists,
  updateStrikeToken,
  updateSlippage,
  addNotification,
  clearNotifications,
  selectPreferredChain,
  setShowBalances,
  setCanPermit,
  setInfiniteRiskyApproval,
  setAcceptedTOS,
} from './actions'
import { UserState, OnboardingState, Token, TokenList, Notification, NotificationTypes } from './reducer'
import { DEFAULT_SLIPPAGE_TOLERANCE } from '@/constants/index'
import { useWeb3React } from '@web3-react/core'
import { DEFAULT_RISKY_TOKENS } from '@/constants/tokenLists/risky'
import { getLayerByChainId } from '@/utils/getLayerByChainId'
import useToast, { constructToastContent } from '@/hooks/utils/useToast'

const identifier = `@primitive/agreement`

const tosId = `@primitive/tos`

export const getOnboardingStatus = (): OnboardingState => {
  const agreementStatus = localStorage.getItem(identifier)
  return OnboardingState[agreementStatus ? agreementStatus : '']
}

export const setOnboardingStatus = () => {
  localStorage.setItem(identifier, OnboardingState.Agreed)
}

export const getTOSStatus = () => {
  const tos = localStorage.getItem(tosId)
  return tos
}

export const useUser = (): UserState => {
  const state = useSelector<AppState, AppState['user']>((state) => state.user)
  return state
}

export const useSetAcceptedTOS = () => {
  const dispatch = useDispatch<AppDispatch>()

  const [_, setStatus] = useLocalStorage(tosId)

  return useCallback(
    (acceptedTOS: boolean) => {
      setStatus(acceptedTOS)
      dispatch(setAcceptedTOS(acceptedTOS))
    },
    [dispatch]
  )
}

export const useUpdateOnboarding = (): ((onboarding: OnboardingState) => void) => {
  const dispatch = useDispatch<AppDispatch>()

  const [_, setStatus] = useLocalStorage(identifier, null)

  return useCallback(
    (onboarding: OnboardingState) => {
      setStatus(onboarding as any)
      dispatch(updateOnboarding(onboarding))
    },
    [dispatch]
  )
}

export const useAddRiskyTokenList = (): ((riskyTokenList: TokenList) => void) => {
  const dispatch = useDispatch()
  const state = useSelector<AppState, AppState['user']>((state) => state.user)
  return useCallback(
    (riskyTokenList: TokenList) => {
      if (state.riskyTokenLists[riskyTokenList.name]) return
      const updatedRiskyTokenLists = {
        ...state.riskyTokenLists,
        [riskyTokenList.name]: riskyTokenList,
      }
      dispatch(updateRiskyTokenLists(updatedRiskyTokenLists))
      localStorage.setItem(
        'riskyTokenLists',
        JSON.stringify({
          ...state.riskyTokenLists,
          [riskyTokenList.name]: riskyTokenList,
        })
      )
    },
    [dispatch, state.riskyTokenLists]
  )
}

export const useToggleRiskyTokenListStatus = (): ((riskyTokenListName: string) => void) => {
  const dispatch = useDispatch()
  const state = useSelector<AppState, AppState['user']>((state) => state.user)

  return useCallback(
    (riskyTokenListName: string) => {
      if (!state.riskyTokenLists[riskyTokenListName]) return
      const updatedTokenList = { ...state.riskyTokenLists[riskyTokenListName] }

      if (updatedTokenList.status) {
        updatedTokenList.status = false
      } else {
        updatedTokenList.status = true
      }
      dispatch(
        updateRiskyTokenLists({
          ...state.riskyTokenLists,
          [riskyTokenListName]: updatedTokenList,
        })
      )
      localStorage.setItem(
        'riskyTokenLists',
        JSON.stringify({
          ...state.riskyTokenLists,
          [riskyTokenListName]: updatedTokenList,
        })
      )
    },
    [state.riskyTokenLists]
  )
}

export const useRiskyTokens = (): Token[] => {
  const state = useSelector<AppState, AppState['user']>((state) => state.user)
  const { chainId } = useWeb3React()

  return useMemo(() => {
    if (chainId && getLayerByChainId(chainId) === 'TEST') {
      return DEFAULT_RISKY_TOKENS[chainId]
    } else {
      return Object.keys(state.riskyTokenLists).reduce((tokens, tokenList) => {
        if (state.riskyTokenLists[tokenList].status) {
          return [...tokens, ...state.riskyTokenLists[tokenList].tokens]
        }
        return [...tokens] as any
      }, [])
    }
  }, [state.riskyTokenLists, chainId])
}

export const useUpdateStrikeToken = (): ((strikeToken: Token, chainId: number | string) => void) => {
  const dispatch = useDispatch()
  return useCallback(
    (strikeToken: Token, chainId: number | string) => {
      if (!strikeToken ?? !chainId) return undefined
      localStorage.setItem('strikeToken' + chainId, JSON.stringify(strikeToken))
      dispatch(updateStrikeToken(strikeToken))
    },
    [dispatch]
  )
}

export const useUpdateSlippage = (): ((slippage: string) => void) => {
  const dispatch = useDispatch<AppDispatch>()
  return useCallback(
    (slippage: string) => {
      localStorage.setItem('slippage', JSON.stringify(slippage))
      dispatch(updateSlippage(slippage))
    },
    [dispatch]
  )
}

export const useAddNotification = (): ((type: NotificationTypes, message: string, hash?: string) => void) => {
  const renderToast = useToast()
  const dispatch = useDispatch()
  const state = useSelector<AppState, AppState['user']>((state) => state.user)
  const toastTypeMapping = {
    [NotificationTypes.SUCCESS]: 'success',
    [NotificationTypes.FAILURE]: 'error',
    [NotificationTypes.REJECTION]: 'error',
    [NotificationTypes.PENDING]: 'loading',
  }

  return useCallback(
    (type: NotificationTypes, message: string, hash?: string) => {
      const newNotification = { type, message, timestamp: Date.now(), hash }
      renderToast({ body: constructToastContent(type, message, hash), type: toastTypeMapping[type] })
      dispatch(addNotification(newNotification))
      localStorage.setItem('notifications', JSON.stringify(state.notifications.concat(newNotification)))
    },
    [state.notifications, dispatch]
  )
}

export const useClearNotifications = () => {
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch(clearNotifications())
    localStorage.setItem('notifications', JSON.stringify([]))
  }, [dispatch])
}

export const useselectPreferredChain = (): ((chainId: number) => void) => {
  const dispatch = useDispatch()

  return useCallback(
    (chainId: number) => {
      dispatch(selectPreferredChain(chainId))
      localStorage.setItem('preferredChain', chainId.toString())
    },
    [dispatch]
  )
}
export const useSetShowBalances = () => {
  const dispatch = useDispatch()
  return useCallback(
    (showBalances: boolean) => {
      dispatch(setShowBalances(showBalances))
    },
    [dispatch]
  )
}

export const useSetCanPermit = () => {
  const dispatch = useDispatch()
  return useCallback(
    (canPermit: boolean) => {
      dispatch(setCanPermit(canPermit))
    },
    [dispatch]
  )
}

export const useSetInfiniteRiskyApproval = () => {
  const dispatch = useDispatch()
  return useCallback(
    (infiniteRiskyApproval: boolean) => {
      localStorage.setItem('infiniteRiskyApproval', JSON.stringify(infiniteRiskyApproval))
      dispatch(setInfiniteRiskyApproval(infiniteRiskyApproval))
    },
    [dispatch]
  )
}
