import { FeeData, JsonRpcSigner } from '@ethersproject/providers'
import { BigNumber } from 'ethers'
import { formatUnits } from 'ethers/lib/utils'

import { TxPayload } from '@/utils/sendTransaction'

/**
 * @notice Attaches a gasLimit amount in wei to tx payload
 * @param library Injected web3 provider
 * @param tx Constructed transaction payload with a target and data
 * @param gasLimitMultiplierBasisPts Margin of error to apply to gas estimate in bps
 * @returns constructed payload with an applied gas estimate if successful
 */
export default async function fetchAndApplyGasEstimate(
  library: any,
  tx: TxPayload,
  gasLimitMultiplierBasisPts = 13500
): Promise<{ payload: TxPayload; success: boolean; error?: any }> {
  // gas estimate simulates a transaction, which must have a signer
  const signer: JsonRpcSigner = await library.getSigner()

  try {
    const estimate = await signer.estimateGas(tx)
    // applies a 135% buffer to ensure the transaction does not run out of gas
    const gasLimit = estimate.mul(gasLimitMultiplierBasisPts).div(1e4).toHexString()

    return {
      payload: {
        ...tx,
        gasLimit,
      },
      success: true,
    }
  } catch (error) {
    return {
      payload: tx,
      success: false,
      error,
    }
  }
}

/**
 *
 * @param feeData Network fee information returned by web3 provider
 * @param gasLimit Max amount of gas to spend for a transaction
 * @returns baseFee; gas price paid in gwei, gasPrice if legacy transaction
 * @returns tip; additional gas price paid in gwei to miners
 * @returns total; total max gas spent multiplied by total price per gas in gwei formatted in ether
 */
export function getNetworkFeeDisplayInfo(
  feeData?: FeeData,
  gasLimit?: string
): { baseFee: string; tip?: string; total?: string } | undefined {
  if (!feeData) return undefined
  const baseFee = formatUnits(feeData.maxFeePerGas ? feeData.maxFeePerGas : feeData.gasPrice ?? '0', 'gwei')
  const tip = formatUnits(feeData.maxPriorityFeePerGas ?? '0', 'gwei')
  return {
    baseFee,
    tip,
    total: gasLimit
      ? formatUnits(
          BigNumber.from(gasLimit).mul(BigNumber.from(feeData.maxFeePerGas ?? '1').add(feeData.maxPriorityFeePerGas ?? '0')),
          'ether'
        )
      : undefined,
  }
}
