import { Web3Provider } from '@ethersproject/providers'

import { NetworkConnector } from '@web3-react/network-connector'
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { AbstractConnector } from '@web3-react/abstract-connector'
import { ACTIVE_NETWORK } from '../constants'

import getLibrary from '@/utils/getLibrary'
export interface Wallet {
  connector: AbstractConnector
  name: string
  icon: string
}

export const RPC_URLS = {
  1: process.env.NEXT_PUBLIC_MAINNET_RPC,
  4: process.env.NEXT_PUBLIC_RINKEBY_RPC,
} as { [k: number]: string }

export const network = new NetworkConnector({
  urls: RPC_URLS,
  defaultChainId: ACTIVE_NETWORK,
})
// TODO: move this to constants/chains.ts
export const supportedChainIds = [1, 4] // Previously [1, 3, 4, 5, 42, 42161, 421611]

export const injected = new InjectedConnector({
  supportedChainIds,
})

// no coinbase testnet, requires infura mainnet node URL
export const walletconnect = new WalletConnectConnector({
  rpc: RPC_URLS,
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
})
