import React from 'react'
import Link from 'next/link'
import { useClearNotifications, useUser } from '@/state/user/hooks'
import getEtherscanLink from '@/utils/getEtherScanLink'
import { useActiveWeb3React } from '@/hooks/web3'
import dayjs from 'dayjs'
import { Notification, NotificationTypes } from '@/state/user/reducer'
import * as ScrollArea from '@radix-ui/react-scroll-area'
import Box from '@/ui/Box'
import Text from '@/ui/Text'
import Heading from '@/ui/Heading'
import Button from '@/ui/Button'
import { styled } from '@/ui/theme'

const SCROLLBAR_SIZE = 10

const StyledScrollArea = styled(ScrollArea.Root, {
  height: 400,
  overflow: 'hidden',
})

const StyledViewport = styled(ScrollArea.Viewport, {
  width: '100%',
  height: '100%',
  borderRadius: 'inherit',
})

const StyledScrollbar = styled(ScrollArea.Scrollbar, {
  display: 'flex',
  borderRadius: SCROLLBAR_SIZE,
  // ensures no selection
  userSelect: 'none',
  // disable browser handling of all panning and zooming gestures on touch devices
  touchAction: 'none',
  padding: 2,
  background: 'hsl(0deg 0% 50% / 10%)',
  transition: 'background 160ms ease-out',
  '&:hover': { background: 'hsl(0deg 0% 50% / 15%)' },
  '&[data-orientation="vertical"]': { width: SCROLLBAR_SIZE },
  '&[data-orientation="horizontal"]': {
    flexDirection: 'column',
    height: SCROLLBAR_SIZE,
  },
})

const StyledThumb = styled(ScrollArea.Thumb, {
  flex: 1,
  background: '$neutral10',
  borderRadius: SCROLLBAR_SIZE,
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    minWidth: 44,
    minHeight: 44,
  },
})

const notificationColorMap: Record<any, [string, number]> = {
  [NotificationTypes.SUCCESS]: ['primary', 7],
  [NotificationTypes.FAILURE]: ['danger', 6],
  [NotificationTypes.REJECTION]: ['danger', 6],
  [NotificationTypes.ERROR]: ['danger', 7],
  [NotificationTypes.PENDING]: ['warning', 6],
}

type NotificationCardProps = {
  notification: Notification
  chainId?: number
}

const NotificationCard: React.FC<NotificationCardProps> = ({ chainId, notification }) => {
  const mappedColor = notificationColorMap[notification.type] || ['primary', 7]
  const makecolor = (n = 0) => {
    return `$colors$${mappedColor[0]}${Math.max(mappedColor[1] + n, 1)}`
  }
  return (
    <Box
      css={{
        border: '1px solid $colors$neutral5',
        borderColor: makecolor(-1),
        rounded: '$md',
        py: '$2',
        px: '$4',
        mb: '$2',
        fontSize: '$xs',
      }}
    >
      <Box display="flex" justify="spaceBetween" align="center">
        <Box>
          <Text as="span" size="sm">
            Transaction{' '}
          </Text>
          <Text as="span" size="xs" css={{ color: makecolor(2) }}>
            ({notification.type})
          </Text>
        </Box>
        {notification.hash ? (
          <Link href={getEtherscanLink(chainId ?? 1, notification.hash, 'TRANSACTION')}>
            <a className="text-xs">View Tx</a>
          </Link>
        ) : (
          ''
        )}
      </Box>
      <Text size="xs" color="loContrast">
        {dayjs(notification.timestamp).format('MMMM DD, YYYY @ h:mm A')}
      </Text>
    </Box>
  )
}

const NotificationSettings: React.FC = () => {
  const user = useUser()
  const { chainId } = useActiveWeb3React()

  const clearNotifications = useClearNotifications()
  return (
    <Box display="flex-col">
      <Box display="flex" justify="spaceBetween" align="start" css={{ mb: '$4' }}>
        <Heading css={{ display: 'flex', flexDirection: 'column' }}>
          Notification Log
          <Text size="xs" color="loContrast">
            View previous transaction information.
          </Text>
        </Heading>
        <Button onClick={clearNotifications} aria-label="clear notification log">
          Clear
        </Button>
      </Box>
      <StyledScrollArea>
        <StyledViewport>
          {user.notifications.length > 0 ? (
            user.notifications
              .slice(0)
              .reverse()
              .map((notification) => (
                <NotificationCard key={notification.timestamp} notification={notification} chainId={chainId} />
              ))
          ) : (
            <Text style={{ textAlign: 'center' }}>No Notifications</Text>
          )}
        </StyledViewport>
        <StyledScrollbar>
          <StyledThumb />
        </StyledScrollbar>
      </StyledScrollArea>
    </Box>
  )
}

export default NotificationSettings
