import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { Provider } from 'react-redux'
import Head from 'next/head'

import store from '@/state/index'
import '../styles/tailwind.css'
import TransactionsUpdater from '@/state/transactions/updater'
import ChainIdUpdater from '@/state/network/chainIdUpdater'
import dynamic from 'next/dynamic'

import TopBar from '@/components/Topbar'
import { Footer } from '@/components/Footer'
import getLibrary from '@/utils/getLibrary'
import { Web3ReactProvider } from '@web3-react/core'
import Web3ReactManager from '@/components/Web3ReactManager'
import { ErrorBoundary } from 'react-error-boundary'
import M from '@/singletons/MonitoringSingleton'

const Web3ProviderNetwork = dynamic(() => import('../components/Web3ProviderNetwork'), { ssr: false })

interface JsonRpcRequest {
  id: string | undefined
  jsonrpc: '2.0'
  method: string
  params?: Array<any>
}

interface JsonRpcResponse {
  id: string | undefined
  jsonrpc: '2.0'
  method: string
  result?: unknown
  error?: Error
}

type JsonRpcCallback = (error: Error, response: JsonRpcResponse) => unknown

if (typeof window !== 'undefined' && !!window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false
  const classicSend = window.ethereum.send

  window.ethereum.send = function newSend(arg1: string | JsonRpcRequest, arg2?: Array<unknown> | JsonRpcCallback) {
    if (typeof arg1 === 'string')
      return classicSend(arg1, arg2).catch((e) => {
        M.captureIssue(e, {
          eventName: 'send_error',
          method: arg1,
          params: arg2,
        })
        throw e
      })

    if (typeof arg2 === 'function') {
      return classicSend(arg1, (err, res) => {
        if (err) {
          M.captureIssue(err, {
            eventName: 'send_error',
            req: arg1,
            res,
          })
        }
        arg2(err, res)
      })
    }

    return classicSend(arg1, arg2)
  }
}

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}

const Updater = () => {
  return (
    <>
      <TransactionsUpdater />
      <ChainIdUpdater />
    </>
  )
}

export default function App({ Component, pageProps }): any {
  const router = useRouter()

  useEffect((): (() => void) => {
    // Track page views
    const handleRouteChange = (url, { shallow }) => {
      M.captureEvent('$pageview', {
        shallow,
      })
    }

    const handleRouteChangeError = (err, url, { shallow }) => {
      M.captureIssueEvent(err, 'pageview', {
        shallow,
        url,
      })
    }

    router.events.on('routeChangeComplete', handleRouteChange)
    router.events.on('routeChangeError', handleRouteChangeError)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
      router.events.off('routeChangeError', handleRouteChangeError)
    }
  }, [])

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Head>
        <title>Primitive Interface</title>
      </Head>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Web3ProviderNetwork getLibrary={getLibrary}>
          <Web3ReactManager>
            <Provider store={store}>
              <div
                className="dark-theme"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'absolute',
                  right: 0,
                  left: 0,
                  minHeight: '100vh',
                }}
              >
                <TopBar />
                <Component {...pageProps} />
                <Updater />
                <Footer />
              </div>
            </Provider>
          </Web3ReactManager>
        </Web3ProviderNetwork>
      </Web3ReactProvider>
    </ErrorBoundary>
  )
}
