import Deployments from './abis/deployments.json'
import { Token, WETH9 } from '@uniswap/sdk-core'
import { SupportedCurrencySlugs } from '@/utils/slugs'

export type TokenMapping = { [key: number]: Token }
export type TokenFromList = {
  address: string
  name?: string
  symbol?: string
  decimals?: number
  logoURI?: string
  backgroundColor?: string
}

export const TEST_WETH: TokenMapping = {
  4: new Token(4, '0xc778417E063141139Fce010982780140Aa0cD5Ab', 18, 'WETH9', 'Wrapped Ether'),
}

export const STABLECOINS: TokenMapping = {
  1: new Token(1, '0x6B175474E89094C44Da98b954EedeAC495271d0F', 18, 'DAI', 'Dai Stablecoin'),
  4: new Token(4, '0x522064c1EafFEd8617BE64137f66A71D6C5c9aA3', 6, 'USDC', 'USDC Coin'),
  42: new Token(42, Deployments[42].kovan.contracts.USDC.address, 6, 'USDC', 'USDC Coin'),
  42161: new Token(42161, '0x6B175474E89094C44Da98b954EedeAC495271d0F', 18, 'DAI', 'Dai Stablecoin'),
}

export const USDC: TokenMapping = {
  4: new Token(4, Deployments[4].rinkeby.contracts.USDC.address, 6, 'USDC', 'USDC Coin'),
  42: new Token(42, Deployments[42].kovan.contracts.USDC.address, 6, 'USDC', 'USDC Coin'),
}

export const DAI: TokenMapping = {
  1: STABLECOINS[1],
  4: new Token(4, Deployments[4].rinkeby.contracts.Dai.address, 18, 'Dai', 'Dai Stablecoin'),
  42: new Token(42, Deployments[42].kovan.contracts.Dai.address, 18, 'Dai', 'Dai Stablecoin'),
}

export const SUPPORTED_CURRENCY_MAPPING: { [key: string]: TokenMapping } = {
  ETH: WETH9,
  // [SupportedCurrencySlugs.ETH]: WETH9,
  // [SupportedCurrencySlugs.USDC]: USDC,
}
