import posthog from 'posthog-js'
import * as Sentry from '@sentry/nextjs'
import { CaptureContext, ScopeContext } from '@sentry/types'
import { isFn } from '@/utils/assertions'

const isDev = process.env.NODE_ENV === 'development'
const replaceSpaces = (eventName: string, newSpacingCharacter = '_') => {
  return eventName.replace(' ', newSpacingCharacter)
}
export class Monitor<
  EventData = posthog.Properties,
  IssueProps extends Object = Partial<ScopeContext>,
  IssueData extends Object = ScopeContext['extra'],
  EventPropFn = EventData | ((...args: any[]) => EventData)
> {
  static UNKNOWN_EVENT_NAME = 'UNKNOWN_EVENT_NAME'

  eventLogger?: typeof posthog
  issueLogger?: typeof Sentry

  private _initialized = false
  get initialized() {
    return this._initialized
  }

  // constructor() {}
  init(no_really_force_init_string?: string) {
    if (this._initialized && no_really_force_init_string !== 'no_really_force_init') return false

    const enableMonitoring = true
    try {
      if (enableMonitoring) {
        posthog.init(process.env.NEXT_PUBLIC_POSTHOG as string, {
          api_host: 'https://app.posthog.com',
          // autocapture: false,
          persistence: 'localStorage',
        })

        this.eventLogger = posthog
      }
    } catch (e) {
      console.error('Unable to initialize event logging', e)
    }

    try {
      const sentryUrl = process.env.NEXT_PUBLIC_SENTRY as string
      const sentryId = parseInt(sentryUrl.split('/').reverse()[0]) || (sentryUrl as any)
      Sentry.init({
        // debug: isDev,
        dsn: sentryUrl,
        integrations: enableMonitoring ? [new posthog.SentryIntegration(posthog, 'rmm-interface', sentryId) as any] : [],
      })

      this.issueLogger = Sentry
    } catch (e) {
      console.error('Unable to initialize error logging', e)
    }

    this._initialized = true
  }

  get userOptOutStatus() {
    return this.eventLogger?.has_opted_out_capturing
  }

  debug(enable = true) {
    if (!this._initialized) this.init()
    if (enable) this.eventLogger?.debug()
    else console.log(`Posthog doesn't actually have a way to turn off debugging. Just reload lol.`)
  }

  getEventName(props: any, prefix = '') {
    const name =
      props?.['data-m'] || props?.['aria-label'] || props?.title || props?.['data-cy'] || Monitor.UNKNOWN_EVENT_NAME
    return `${prefix}${name}`
  }

  resetEventLoggerIdentity() {
    if (!this._initialized) this.init()
    this.eventLogger?.reset()
  }

  captureEvent(eventName: string, data?: EventData) {
    if (!this._initialized) this.init()
    this.eventLogger?.capture(replaceSpaces(eventName), data)
  }

  captureIssue(exception: any, data?: IssueData, ctx: Partial<IssueProps> = {}) {
    if (!this._initialized) this.init()

    this.issueLogger?.captureException(exception, {
      ...ctx,
      extra: data,
    } as any)
  }

  captureIssueEvent(exception: any, eventName: string, data: any = {}, ctx: Partial<IssueProps> = {}) {
    this.captureIssue(exception, {
      ...ctx,
      extra: {
        eventName: replaceSpaces(eventName),
        ...data,
      },
    } as any)
  }

  wrapAndCapture(funcToCapture, funcProps, eventName: string, data?: EventData) {
    if (typeof funcToCapture === 'function') funcToCapture(...funcProps)
    this.captureEvent(replaceSpaces(eventName), data)
  }

  captureFn<T extends Function>(funcToCapture: T | undefined, eventName: string, data?: EventPropFn) {
    return (...args) => {
      const eventData = isFn(data) ? data(...args) : data
      this.captureEvent(replaceSpaces(eventName), eventData)
      return funcToCapture && funcToCapture(...args)
    }
  }

  capturePromise(eventName: string, thenData?: EventPropFn, catchData = thenData, triggerIssueOnCatch = false) {
    if (catchData === undefined) catchData = thenData

    return <T extends Promise<unknown>>(P: T) => {
      return P.then((value) => {
        this.captureEvent(`${replaceSpaces(eventName)}_resolved`, isFn(thenData) ? thenData(value) : thenData)
        return value
      }).catch((e) => {
        this.captureEvent(`${replaceSpaces(eventName)}_rejected`, isFn(catchData) ? catchData(e) : catchData)
        if (triggerIssueOnCatch) {
          this.captureIssueEvent(e, `${replaceSpaces(eventName)}_rejected`, isFn(catchData) ? catchData(e) : catchData)
        }
        throw e
      })
    }
  }

  toggleEventMonitoring(shouldCapture: boolean) {
    if (!this._initialized) this.init()
    if (shouldCapture) this.eventLogger?.opt_in_capturing()
    else this.eventLogger?.opt_out_capturing()
  }
}

const MONITORING_SINGLETON = new Monitor()

if (isDev) {
  globalThis['Monitor'] = MONITORING_SINGLETON
}

// const MonitoringFactory = () => {
//   let instance: Monitor

//   return {
//     getInstance: () => {
//       if (!instance) {
//         instance = new Monitor()
//         // delete instance.constructor
//       }
//       return Object.freeze(instance)
//     },
//   }
// }

export default MONITORING_SINGLETON
