import type * as Stitches from '@stitches/react'
export {
  styled,
  css,
  theme,
  createTheme,
  getCssText,
  globalCss,
  keyframes,
  config,
  reset,
  darkTheme,
  mapTheme,
  createInternalColors,
  useBreakpoint,
} from './stitches.config'
import { darkTheme, globalCss, theme } from './stitches.config'
export type { CSS, ColorProperties } from './stitches.config'

export type { VariantProps, ComponentProps } from '@stitches/react'

// Have to call in order to make it actually generate.
console.log(theme.toString())
console.log(darkTheme.toString())

globalCss({
  '.dark-theme': {
    background: '$neutral1',
    color: '$hiContrast',
  },
  '.theme-default': {
    background: '$neutral1',
    color: '$hiContrast',
  },
})()
