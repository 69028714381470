import React from 'react'
import { styled, CSS, VariantProps } from '@/ui/theme'
import { InputStyles, InputElementStyles, InputGroupStyles } from './Input.styles'
import { withStyledRef, getValidChildren, withId } from '@/ui/theme/utils'
import M from '@/singletons/MonitoringSingleton'
import cx from 'classnames'

const StyledInput = styled('input', InputStyles)

type InputProps = {
  disabled?: boolean
}

export const Input = withStyledRef<typeof StyledInput, InputProps>('Input', ({ state, type, ...props }, fwdRef) => {
  const decimalProps = {
    min: '0.01',
    step: '0.01',
    inputMode: 'decimal' as const,
    autoComplete: 'off',
    autoCorrect: 'off',
    pattern: '^[0-9]*[.,]?[0-9]*$',
    placeholder: '0.0',
    minLength: 1,
    maxLength: 79,
    spellCheck: false,
  }

  return (
    <StyledInput
      ref={fwdRef}
      type="text"
      state={state}
      aria-invalid={state === 'invalid' ? true : undefined}
      {...(type === 'decimal' ? decimalProps : {})}
      {...props}
      onBlur={M.captureFn(props.onBlur, M.getEventName(props, 'blur:'), {
        validity: state,
        error: props['title'],
      })}
    />
  )
})

export const InputLeftElement = withId(
  'InputLeftElement',
  styled('div', InputElementStyles, {
    left: 0,
  })
)

export const InputRightElement = withId(
  'InputRightElement',
  styled('div', InputElementStyles, {
    right: 0,
  })
)

const StyledInputGroup = styled('div', InputGroupStyles)

type InputGroupProps = VariantProps<typeof StyledInput> & {
  disabled?: boolean
  readOnly?: boolean
}

// TODO - use the new mapChildren function and clean this up.
// Also - note the discussion about the way stitches handles the css prop in their discord - might make more sense to pre-gen the css() classnames and append them?
// Also - Enforce only one input in here.
// Also - Potentially set up a primitive uikit config singleton to pull some of these padding values from for future user extendability
// Future - See if it's worth React.memo-ing these components with just a custom childId map comparison.
export const InputGroup = withStyledRef<typeof StyledInputGroup, InputGroupProps>(
  'InputGroup',
  ({ children, ...props }, ref) => {
    const validChildren = getValidChildren(children)
    const childIds = validChildren.map((c) => c.type?.id)

    const inputCSS = {} as CSS

    // TODO: I could also loop through the validChildren (like everyone else does)
    // UPDATE: The new mapChildren function also resolves this.
    // and check if InputElement has side=left and side=right. That would allow a single InputElement.
    // And could open up the possibility of not having one at all in the future.
    if (childIds.includes('InputLeftElement')) inputCSS.paddingLeft = '$10'
    if (childIds.includes('InputRightElement')) inputCSS.paddingRight = '$10'

    if (childIds.includes('InputLeftAddon')) {
      inputCSS.borderTopLeftRadius = 0
      inputCSS.borderBottomLeftRadius = 0
    }

    if (childIds.includes('InputRightAddon')) {
      inputCSS.borderTopRightRadius = 0
      inputCSS.borderBottomRightRadius = 0
    }

    const clones = validChildren.map((child) => {
      if (child.type?.id?.includes('Input')) {
        return React.cloneElement(child, {
          ...props,
          ...child.props,

          // TODO: make sure all the Input elements actually follow the variant spec
          css:
            child.type.id === 'Input' && Object.keys(inputCSS).length !== 0
              ? { ...inputCSS, ...(child.props.css || {}) }
              : child.props.css,
        })
      }

      return child
    })

    return (
      <StyledInputGroup ref={ref} {...props} role="group">
        {clones}
      </StyledInputGroup>
    )
  }
)
