import React from 'react'
import cogoToast from 'cogo-toast'
import { Fragment, useCallback } from 'react'
import getEtherscanLink from '@/utils/getEtherScanLink'

export const addToast = ({ body, type = 'success', hideAfter = 10 }) =>
  cogoToast[type](<Toast body={body} />, {
    position: 'bottom-right',
    hideAfter,
    bar: {
      color: 'var(--grey)',
      style: 'solid',
      size: '1px',
    },
  })

export default function useToast() {
  return useCallback(addToast, [])
}

const Toast = ({ body }) => <span className="p-4">{body}</span>

export const constructToastContent = (notificationType, message, hash) => {
  return (
    <Fragment>
      {message}
      {hash && (
        <a
          className="underline pl-2 text-sm"
          target="_blank"
          rel="noopener noreferrer"
          href={getEtherscanLink(4, hash, 'TRANSACTION')}
        >
          View Tx
        </a>
      )}
    </Fragment>
  )
}
