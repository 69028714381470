import { createAction } from '@reduxjs/toolkit'
import { Operation } from '@/constants/index'
import { ChainId } from '@uniswap/sdk'
import { TransactionKind } from 'src/eth-tx-manager/types'

export type TransactionState = {
  [chainId in ChainId]: {
    [txHash: string]: TransactionKind
  }
}

export const addTransaction = createAction<{
  tx: TransactionKind
  orderType: Operation
}>('transactions/addTransaction')

export const clearAllTransactions = createAction<{ chainId: number }>('transactions/clearAllTransactions')
export const updateTransaction = createAction<{
  chainId: number
  tx: TransactionKind
}>('transactions/updateTransaction')
