import { createReducer } from '@reduxjs/toolkit'
import { setChainSelectModal, setExpiryModal, setIncreaseModal, setMarketModal, setRemoveModal, setWalletModal } from './actions'

export type ModalState = {
  showMarketModal: boolean
  showExpiryModal: boolean
  showRemoveModal: boolean
  showIncreaseModal: boolean
  showChainSelectModal: boolean
  showWalletModal: boolean
}

export const initialState: ModalState = {
  showMarketModal: false,
  showExpiryModal: false,
  showRemoveModal: false,
  showIncreaseModal: false,
  showChainSelectModal: false,
  showWalletModal: false,
}

export default createReducer<ModalState>(initialState, (builder) =>
  builder
    .addCase(setMarketModal, (state, { payload: showMarketModal }) => {
      return { ...state, showMarketModal }
    })
    .addCase(setExpiryModal, (state, { payload: showExpiryModal }) => {
      return { ...state, showExpiryModal }
    })
    .addCase(setRemoveModal, (state, { payload: showRemoveModal }) => {
      return { ...state, showRemoveModal }
    })
    .addCase(setIncreaseModal, (state, { payload: showIncreaseModal }) => {
      return { ...state, showIncreaseModal }
    })
    .addCase(setWalletModal, (state, { payload: showWalletModal }) => {
      return { ...state, showWalletModal }
    })
    .addCase(setChainSelectModal, (state, { payload: showChainSelectModal }) => {
      return { ...state, showChainSelectModal }
    })
)
