import { createAction } from '@reduxjs/toolkit'
import { BigNumberish } from 'ethers'

export const updateHistoricalPosition = createAction<{
  totalLiquidity: BigNumberish
  totalRisky: BigNumberish
  totalStable: BigNumberish
}>('manage/updateHistoricalPosition')

export const typeRemoveInput = createAction<{ typedValue: string }>('manage/typeRemoveInput')
